.box {
  width: 410px;
  overflow: hidden;
  margin: 0 auto;
  #mrotter {
    #left-eye-open {
       opacity: 0;
    }
    #left-eye-open-highlight {
        opacity: 0;
    }
    #right-eye-open {
        opacity: 0;
    }
    #right-eye-open-highlight {
        opacity: 0;
    }
    #otter-head {
        transform-origin: 50% 50%;
    }
    #map {
        transform-origin: 50% 50%;
    }
    #hands {
        transform-origin: 50% 50%;
    }
  }
}
