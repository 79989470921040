@import '../../../variables';

.ToolbarButtonComponent {
  .toolbarIcon {
    font-size: 39px;
    color: $toolbar-icon-color;
  }
  
  .tool {
    width: 44px;
    height: 44px;
    text-align: center;
    
    // Added media query to make sure that on mobile devices
    // the hover styles do not get added on click
    @media screen and (hover: hover) {
      &.tool-hover:hover {
        cursor: pointer;
        transform: scale(1.3);
        transition: $hover-transform-transition;
        @media(max-width: 1200px) {
          transform: scale(1.15);
          transition: $hover-transform-transition;
        }
      }

      &.no-tool-hover {
        cursor: default;
      }
    }
    
    &.tool-inactive {
      opacity: 0.5;
    }
  }

  // Added to make Storybook work correctly
  button {
    all: unset;
  }
  
  .activeToolbarIconColor {
    color: $toolbar-active-icon-color;
  }
}
