@import '../../variables.scss';

.generic-not-found {
  width: 60%;
  text-align: center;
  margin: 0 auto;
  h1 {
    font-size: 96px;
    color: $tertiary-color;
    margin-bottom: -30px;
  }
  h2 {
    font-size: 52px;
    color: $tertiary-color;
  }
  p {
    margin-top: 25px;
    font-size: 22px;
    color: #333333;
  }
  img {
    margin-top: 0px;
  }
  .link-button {
    display: inline-block;
    width: auto;
    margin: 0 auto;
    background-color: $tertiary-color;
    border-radius: 40px;
    color: #fff;
    font-family: 'Fredoka One', cursive;
    height: 35px;
    text-align: center;
    text-transform: uppercase;
    border: 3px solid brown;
    width: auto;
    padding-left: 10px;
    padding-right: 10px;
    margin-left: 5px;
    margin-right: 5px;
    &:hover {
      text-decoration: none;
      animation: swing 1s;
    }
  }
}