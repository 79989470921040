@import '../../../variables';

.DrawerButtonComponent {
  .colorBtn {
    width: 44px;
    height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    
    &:hover {
      cursor: pointer;
      transform: scale(1.3);
      transition: $hover-transform-transition;
      @media(max-width: 1200px) {
        transform: scale(1.15);
        transition: $hover-transform-transition;
      }
    }
  }
  
  .color {
    width: 44px;
    height: 44px;
    border-radius: 50%;
    @media(max-width: 1200px) {
      width: 39px;
      height: 39px;
    }
  }
  
  .background {
    &:hover {
      cursor: pointer;
      transform: scale(1.3);
      transition: $hover-transform-transition;
      @media (max-width: 900px) {
        transform: scale(1.15);
        transition: $hover-transform-transition;
      }
    }
  }
  
  .activeSticker {
    transform: scale(1.1);
    @media(max-width: 900px) {
      transform: scale(1.05);
    }
  }
  
  .activeBackground {
    transform: scale(1.3);
    @media(max-width: 900px) {
      transform: scale(1.15);
    }
  }
  
  .activeColor {
    transform: scale(1.3);
    @media(max-width: 1200px) {
      transform: scale(1.15);
    }
  }
  
  .sticker {
    &:hover {
      cursor: pointer;
      transform: scale(1.1);
      transition: $hover-transform-transition;
      @media (max-width: 900px) {
        transform: scale(1.05);
        transition: $hover-transform-transition;
      }
    }
  }
  
  .drawerImage, .background, .sticker {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .background {
    width: 100px;
    height: 100px;
    @media (max-width: 1100px) {
      height: 80px;
      width: 80px;
    }
    
    .drawerImage {
      width: 100px;
      height: 100px;
      @media (max-width: 1100px) {
        height: 80px;
        width: 80px;
      }
    }
  }
  
  .sticker {
    width: 100px;
    height: 100px;
    @media (max-width: 1100px) {
      height: 80px;
      width: 80px;
    }
    
    .drawerImage {
      width: 100px;
      height: 100px;
      
      @media (max-width: 1100px) {
        height: 80px;
        width: 80px;
      }
      
      @media (max-width: 900px) {
        height: 70px;
        width: 70px;
      }
      
      @media (max-width: 800px) {
        height: 60px;
        width: 60px;
      }
    }
  }
  
  .sixteen-nine-ratio {
    height: 56.25px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid darken($toolbar-color, 40%);
    @media (max-width: 1100px) {
      height: 45px;
    }
    background-color: $stickerbook-color;
  }
}
