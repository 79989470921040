@import '../../variables.scss';

.Header {
  background-color: $britannica-brand-primary;
  margin: 0;
  height: 90px;
  z-index: 10;
  @media screen and (max-width: $screen-sm-max + 1) {
    height: 75px;
  }
  
  button {
    all: unset;
  }
  
  .header-nav {
    height: 100%;
    width: 100%;
    max-width: $screen-lg-min;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
}
