@import url(https://fonts.googleapis.com/css?family=Fredoka+One|Open+Sans);
@import url(https://fonts.googleapis.com/css?family=Fredoka+One|Open+Sans);
@import url(https://fonts.googleapis.com/css?family=Fredoka+One|Open+Sans);
@import url(https://fonts.googleapis.com/css?family=Fredoka+One|Open+Sans);
@import url(https://fonts.googleapis.com/css?family=Fredoka+One|Open+Sans);
@import url(https://fonts.googleapis.com/css?family=Fredoka+One|Open+Sans);
@import url(https://fonts.googleapis.com/css?family=Fredoka+One|Open+Sans);
@import url(https://fonts.googleapis.com/css?family=Fredoka+One|Open+Sans);
@import url(https://fonts.googleapis.com/css?family=Fredoka+One|Open+Sans);
@import url(https://fonts.googleapis.com/css?family=Fredoka+One|Open+Sans);
@import url(https://fonts.googleapis.com/css?family=Fredoka+One|Open+Sans);
@import url(https://fonts.googleapis.com/css?family=Fredoka+One|Open+Sans);
@import url(https://fonts.googleapis.com/css?family=Fredoka+One|Open+Sans);
@import url(https://fonts.googleapis.com/css?family=Fredoka+One|Open+Sans);
@import url(https://fonts.googleapis.com/css?family=Fredoka+One|Open+Sans);
@import url(https://fonts.googleapis.com/css?family=Fredoka+One|Open+Sans);
@import url(https://fonts.googleapis.com/css?family=Fredoka+One|Open+Sans);
@import url(https://fonts.googleapis.com/css?family=Fredoka+One|Open+Sans);
@import url(https://fonts.googleapis.com/css?family=Fredoka+One|Open+Sans);
@import url(https://fonts.googleapis.com/css?family=Fredoka+One|Open+Sans);
@import url(https://fonts.googleapis.com/css?family=Fredoka+One|Open+Sans);
@import url(https://fonts.googleapis.com/css?family=Fredoka+One|Open+Sans);
@import url(https://fonts.googleapis.com/css?family=Fredoka+One|Open+Sans);
@import url(https://fonts.googleapis.com/css?family=Fredoka+One|Open+Sans);
@import url(https://fonts.googleapis.com/css?family=Fredoka+One|Open+Sans);
@import url(https://fonts.googleapis.com/css?family=Fredoka+One|Open+Sans);
/*------------------------*/
/*------------------------*/
/*------------------------*/
/*------------------------*/
/* Pablo */
/* Hover Transition */
/* Hover Transition */
/* Pablo */
.create-background {
  background-color: #FDCF56;
  background-image: url("/images/Shell/backgrounds/learning_zone_2_page_bg_create.png");
  background-repeat: repeat;
  background-size: 120px;
  min-height: calc(100vh - 90px); }
  @media screen and (max-width: 1024px) {
    .create-background {
      min-height: calc(100vh - 75px); } }

.play-background, .play-background-solo {
  background-color: #4FD3D7;
  background-image: url("/images/Shell/backgrounds/learning_zone_2_page_bg_play.png");
  background-repeat: repeat;
  background-size: 120px;
  min-height: calc(100vh - 90px); }
  @media screen and (max-width: 1024px) {
    .play-background, .play-background-solo {
      min-height: calc(100vh - 75px); } }

.play-background-browse {
  background-color: #4FD3D7;
  background-image: url("/images/Shell/backgrounds/learning_zone_2_page_bg_play.png");
  background-repeat: repeat;
  background-size: 120px;
  height: 100%; }

.read-background, .read-background-solo {
  background-color: #D299E6;
  background-image: url("/images/Shell/backgrounds/learning_zone_2_page_bg_read.png");
  background-repeat: repeat;
  background-size: 120px;
  min-height: calc(100vh - 90px); }
  @media screen and (max-width: 1024px) {
    .read-background, .read-background-solo {
      min-height: calc(100vh - 75px); } }

.read-background-browse {
  background-color: #D299E6;
  background-image: url("/images/Shell/backgrounds/learning_zone_2_page_bg_read.png");
  background-repeat: repeat;
  background-size: 120px;
  height: 100%; }

.explore-background {
  background-color: #7FD5A0;
  background-image: url("/images/Shell/backgrounds/learning_zone_2_page_bg_explore.png");
  background-repeat: repeat;
  background-size: 120px;
  min-height: calc(100vh - 90px); }
  @media screen and (max-width: 1024px) {
    .explore-background {
      min-height: calc(100vh - 75px); } }

.home-background {
  background-color: #BCE364;
  background-image: url("/images/Shell/backgrounds/learning_zone_2_page_bg_home.png");
  background-repeat: repeat;
  background-size: 120px;
  min-height: calc(100vh - 90px); }
  @media screen and (max-width: 1024px) {
    .home-background {
      min-height: calc(100vh - 75px); } }

.error-background {
  background-color: #E6E6E6;
  background-image: url("/images/Shell/backgrounds/learning_zone_2_page_bg_error.png");
  background-repeat: repeat;
  background-size: 120px;
  min-height: calc(100vh - 90px); }
  @media screen and (max-width: 1024px) {
    .error-background {
      min-height: calc(100vh - 75px); } }

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: "Fredoka One", cursive;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff; }

[tabindex="-1"]:focus {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
          text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  -webkit-text-decoration-skip-ink: none;
          text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent; }
  a:hover {
    color: #0056b3;
    text-decoration: underline; }

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
    color: inherit;
    text-decoration: none; }
  a:not([href]):not([tabindex]):focus {
    outline: 0; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

select {
  word-wrap: normal; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 576px) {
    .container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .container {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .container {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    .container {
      max-width: 1140px; } }

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

.modal-open {
  overflow: hidden; }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }
  .modal.fade .modal-dialog {
    -webkit-transition: -webkit-transform 0.3s ease-out;
    transition: -webkit-transform 0.3s ease-out;
    transition: transform 0.3s ease-out;
    transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
    -webkit-transform: translate(0, -50px);
            transform: translate(0, -50px); }
    @media (prefers-reduced-motion: reduce) {
      .modal.fade .modal-dialog {
        -webkit-transition: none;
        transition: none; } }
  .modal.show .modal-dialog {
    -webkit-transform: none;
            transform: none; }

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem); }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 1rem);
    overflow: hidden; }
  .modal-dialog-scrollable .modal-header,
  .modal-dialog-scrollable .modal-footer {
    flex-shrink: 0; }
  .modal-dialog-scrollable .modal-body {
    overflow-y: auto; }

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem); }
  .modal-dialog-centered::before {
    display: block;
    height: calc(100vh - 1rem);
    content: ""; }
  .modal-dialog-centered.modal-dialog-scrollable {
    flex-direction: column;
    justify-content: center;
    height: 100%; }
    .modal-dialog-centered.modal-dialog-scrollable .modal-content {
      max-height: none; }
    .modal-dialog-centered.modal-dialog-scrollable::before {
      content: none; }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.5; }

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem; }
  .modal-header .close {
    padding: 1rem 1rem;
    margin: -1rem -1rem -1rem auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.5; }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem; }

.modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem; }
  .modal-footer > :not(:first-child) {
    margin-left: .25rem; }
  .modal-footer > :not(:last-child) {
    margin-right: .25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto; }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem); }
    .modal-dialog-scrollable .modal-content {
      max-height: calc(100vh - 3.5rem); }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem); }
    .modal-dialog-centered::before {
      height: calc(100vh - 3.5rem); }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px; } }

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px; } }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #007bff !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #0062cc !important; }

.bg-secondary {
  background-color: #6c757d !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important; }

.bg-success {
  background-color: #28a745 !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important; }

.bg-info {
  background-color: #17a2b8 !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important; }

.bg-warning {
  background-color: #ffc107 !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important; }

.bg-danger {
  background-color: #dc3545 !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important; }

.bg-light {
  background-color: #f8f9fa !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important; }

.bg-dark {
  background-color: #343a40 !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #dee2e6 !important; }

.border-top {
  border-top: 1px solid #dee2e6 !important; }

.border-right {
  border-right: 1px solid #dee2e6 !important; }

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important; }

.border-left {
  border-left: 1px solid #dee2e6 !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #007bff !important; }

.border-secondary {
  border-color: #6c757d !important; }

.border-success {
  border-color: #28a745 !important; }

.border-info {
  border-color: #17a2b8 !important; }

.border-warning {
  border-color: #ffc107 !important; }

.border-danger {
  border-color: #dc3545 !important; }

.border-light {
  border-color: #f8f9fa !important; }

.border-dark {
  border-color: #343a40 !important; }

.border-white {
  border-color: #fff !important; }

.rounded-sm {
  border-radius: 0.2rem !important; }

.rounded {
  border-radius: 0.25rem !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-lg {
  border-radius: 0.3rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: 50rem !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: -webkit-sticky !important;
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sticky-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.vw-100 {
  width: 100vw !important; }

.vh-100 {
  height: 100vh !important; }

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0); }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.m-n1 {
  margin: -0.25rem !important; }

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important; }

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important; }

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important; }

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important; }

.m-n2 {
  margin: -0.5rem !important; }

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important; }

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important; }

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important; }

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important; }

.m-n3 {
  margin: -1rem !important; }

.mt-n3,
.my-n3 {
  margin-top: -1rem !important; }

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important; }

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important; }

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important; }

.m-n4 {
  margin: -1.5rem !important; }

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important; }

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important; }

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important; }

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important; }

.m-n5 {
  margin: -3rem !important; }

.mt-n5,
.my-n5 {
  margin-top: -3rem !important; }

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important; }

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important; }

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  .m-sm-n1 {
    margin: -0.25rem !important; }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important; }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important; }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important; }
  .m-sm-n2 {
    margin: -0.5rem !important; }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important; }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important; }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important; }
  .m-sm-n3 {
    margin: -1rem !important; }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important; }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important; }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important; }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important; }
  .m-sm-n4 {
    margin: -1.5rem !important; }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important; }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important; }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important; }
  .m-sm-n5 {
    margin: -3rem !important; }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important; }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important; }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important; }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  .m-md-n1 {
    margin: -0.25rem !important; }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important; }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important; }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important; }
  .m-md-n2 {
    margin: -0.5rem !important; }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important; }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important; }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important; }
  .m-md-n3 {
    margin: -1rem !important; }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important; }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important; }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important; }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important; }
  .m-md-n4 {
    margin: -1.5rem !important; }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important; }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important; }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important; }
  .m-md-n5 {
    margin: -3rem !important; }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important; }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important; }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important; }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  .m-lg-n1 {
    margin: -0.25rem !important; }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important; }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important; }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important; }
  .m-lg-n2 {
    margin: -0.5rem !important; }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important; }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important; }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important; }
  .m-lg-n3 {
    margin: -1rem !important; }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important; }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important; }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important; }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important; }
  .m-lg-n4 {
    margin: -1.5rem !important; }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important; }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important; }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important; }
  .m-lg-n5 {
    margin: -3rem !important; }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important; }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important; }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important; }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }
  .m-xl-n1 {
    margin: -0.25rem !important; }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important; }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important; }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important; }
  .m-xl-n2 {
    margin: -0.5rem !important; }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important; }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important; }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important; }
  .m-xl-n3 {
    margin: -1rem !important; }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important; }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important; }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important; }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important; }
  .m-xl-n4 {
    margin: -1.5rem !important; }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important; }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important; }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important; }
  .m-xl-n5 {
    margin: -3rem !important; }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important; }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important; }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important; }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important; }

.text-justify {
  text-align: justify !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-lighter {
  font-weight: lighter !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-weight-bolder {
  font-weight: bolder !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #fff !important; }

.text-primary {
  color: #007bff !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #0056b3 !important; }

.text-secondary {
  color: #6c757d !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #494f54 !important; }

.text-success {
  color: #28a745 !important; }

a.text-success:hover, a.text-success:focus {
  color: #19692c !important; }

.text-info {
  color: #17a2b8 !important; }

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important; }

.text-warning {
  color: #ffc107 !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #ba8b00 !important; }

.text-danger {
  color: #dc3545 !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #a71d2a !important; }

.text-light {
  color: #f8f9fa !important; }

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important; }

.text-dark {
  color: #343a40 !important; }

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important; }

.text-body {
  color: #212529 !important; }

.text-muted {
  color: #6c757d !important; }

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.text-decoration-none {
  text-decoration: none !important; }

.text-break {
  word-break: break-word !important;
  overflow-wrap: break-word !important; }

.text-reset {
  color: inherit !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important; }
  a:not(.btn) {
    text-decoration: underline; }
  abbr[title]::after {
    content: " (" attr(title) ")"; }
  pre {
    white-space: pre-wrap !important; }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  @page {
    size: a3; }
  body {
    min-width: 992px !important; }
  .container {
    min-width: 992px !important; }
  .navbar {
    display: none; }
  .badge {
    border: 1px solid #000; }
  .table {
    border-collapse: collapse !important; }
    .table td,
    .table th {
      background-color: #fff !important; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important; }
  .table-dark {
    color: inherit; }
    .table-dark th,
    .table-dark td,
    .table-dark thead th,
    .table-dark tbody + tbody {
      border-color: #dee2e6; }
  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6; } }

body, html {
  margin: 0;
  padding: 0;
  font-family: "Fredoka One", cursive;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh; }
  body *, html * {
    box-sizing: border-box; }

a {
  color: white; }
  a:hover {
    color: antiquewhite; }

/*------------------------*/
/*------------------------*/
/*------------------------*/
/*------------------------*/
/* Pablo */
/* Hover Transition */
/* Hover Transition */
/* Pablo */
.create-background {
  background-color: #FDCF56;
  background-image: url("/images/Shell/backgrounds/learning_zone_2_page_bg_create.png");
  background-repeat: repeat;
  background-size: 120px;
  min-height: calc(100vh - 90px); }
  @media screen and (max-width: 1024px) {
    .create-background {
      min-height: calc(100vh - 75px); } }

.play-background, .play-background-solo {
  background-color: #4FD3D7;
  background-image: url("/images/Shell/backgrounds/learning_zone_2_page_bg_play.png");
  background-repeat: repeat;
  background-size: 120px;
  min-height: calc(100vh - 90px); }
  @media screen and (max-width: 1024px) {
    .play-background, .play-background-solo {
      min-height: calc(100vh - 75px); } }

.play-background-browse {
  background-color: #4FD3D7;
  background-image: url("/images/Shell/backgrounds/learning_zone_2_page_bg_play.png");
  background-repeat: repeat;
  background-size: 120px;
  height: 100%; }

.read-background, .read-background-solo {
  background-color: #D299E6;
  background-image: url("/images/Shell/backgrounds/learning_zone_2_page_bg_read.png");
  background-repeat: repeat;
  background-size: 120px;
  min-height: calc(100vh - 90px); }
  @media screen and (max-width: 1024px) {
    .read-background, .read-background-solo {
      min-height: calc(100vh - 75px); } }

.read-background-browse {
  background-color: #D299E6;
  background-image: url("/images/Shell/backgrounds/learning_zone_2_page_bg_read.png");
  background-repeat: repeat;
  background-size: 120px;
  height: 100%; }

.explore-background {
  background-color: #7FD5A0;
  background-image: url("/images/Shell/backgrounds/learning_zone_2_page_bg_explore.png");
  background-repeat: repeat;
  background-size: 120px;
  min-height: calc(100vh - 90px); }
  @media screen and (max-width: 1024px) {
    .explore-background {
      min-height: calc(100vh - 75px); } }

.home-background {
  background-color: #BCE364;
  background-image: url("/images/Shell/backgrounds/learning_zone_2_page_bg_home.png");
  background-repeat: repeat;
  background-size: 120px;
  min-height: calc(100vh - 90px); }
  @media screen and (max-width: 1024px) {
    .home-background {
      min-height: calc(100vh - 75px); } }

.error-background {
  background-color: #E6E6E6;
  background-image: url("/images/Shell/backgrounds/learning_zone_2_page_bg_error.png");
  background-repeat: repeat;
  background-size: 120px;
  min-height: calc(100vh - 90px); }
  @media screen and (max-width: 1024px) {
    .error-background {
      min-height: calc(100vh - 75px); } }

* {
  box-sizing: border-box; }

*:focus {
  outline: #ff0000 6px dashed !important; }

html, body, #root {
  height: 100vh;
  min-height: 100vh;
  max-height: 100vh;
  margin: 0;
  padding: 0; }

.App {
  text-align: center; }

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none; }

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white; }

.App-link {
  color: #61dafb; }

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

.live-reader {
  width: inherit;
  color: #f8f8f8;
  position: absolute;
  z-index: -1000;
  width: 1px;
  height: 1px;
  overflow: hidden;
  bottom: 0px; }

.SoundWidget {
  margin-right: 25px;
  margin-left: auto; }
  .SoundWidget .sound-btn {
    width: 75px;
    color: white; }

.hide {
  display: none; }

/*------------------------*/
/*------------------------*/
/*------------------------*/
/*------------------------*/
/* Pablo */
/* Hover Transition */
/* Hover Transition */
/* Pablo */
.create-background {
  background-color: #FDCF56;
  background-image: url("/images/Shell/backgrounds/learning_zone_2_page_bg_create.png");
  background-repeat: repeat;
  background-size: 120px;
  min-height: calc(100vh - 90px); }
  @media screen and (max-width: 1024px) {
    .create-background {
      min-height: calc(100vh - 75px); } }

.play-background, .play-background-solo {
  background-color: #4FD3D7;
  background-image: url("/images/Shell/backgrounds/learning_zone_2_page_bg_play.png");
  background-repeat: repeat;
  background-size: 120px;
  min-height: calc(100vh - 90px); }
  @media screen and (max-width: 1024px) {
    .play-background, .play-background-solo {
      min-height: calc(100vh - 75px); } }

.play-background-browse {
  background-color: #4FD3D7;
  background-image: url("/images/Shell/backgrounds/learning_zone_2_page_bg_play.png");
  background-repeat: repeat;
  background-size: 120px;
  height: 100%; }

.read-background, .read-background-solo {
  background-color: #D299E6;
  background-image: url("/images/Shell/backgrounds/learning_zone_2_page_bg_read.png");
  background-repeat: repeat;
  background-size: 120px;
  min-height: calc(100vh - 90px); }
  @media screen and (max-width: 1024px) {
    .read-background, .read-background-solo {
      min-height: calc(100vh - 75px); } }

.read-background-browse {
  background-color: #D299E6;
  background-image: url("/images/Shell/backgrounds/learning_zone_2_page_bg_read.png");
  background-repeat: repeat;
  background-size: 120px;
  height: 100%; }

.explore-background {
  background-color: #7FD5A0;
  background-image: url("/images/Shell/backgrounds/learning_zone_2_page_bg_explore.png");
  background-repeat: repeat;
  background-size: 120px;
  min-height: calc(100vh - 90px); }
  @media screen and (max-width: 1024px) {
    .explore-background {
      min-height: calc(100vh - 75px); } }

.home-background {
  background-color: #BCE364;
  background-image: url("/images/Shell/backgrounds/learning_zone_2_page_bg_home.png");
  background-repeat: repeat;
  background-size: 120px;
  min-height: calc(100vh - 90px); }
  @media screen and (max-width: 1024px) {
    .home-background {
      min-height: calc(100vh - 75px); } }

.error-background {
  background-color: #E6E6E6;
  background-image: url("/images/Shell/backgrounds/learning_zone_2_page_bg_error.png");
  background-repeat: repeat;
  background-size: 120px;
  min-height: calc(100vh - 90px); }
  @media screen and (max-width: 1024px) {
    .error-background {
      min-height: calc(100vh - 75px); } }

.BackToHome {
  margin-left: 25px;
  display: flex;
  align-items: center;
  justify-content: center; }
  .BackToHome .home-btn {
    width: 60px; }
  .BackToHome .branding-btn {
    height: 67px;
    margin-left: 30px; }
  .BackToHome .branding {
    height: 100%; }

.skip-to-main-content-link {
  font-size: 1px;
  width: 1px;
  height: 1px; }

/*------------------------*/
/*------------------------*/
/*------------------------*/
/*------------------------*/
/* Pablo */
/* Hover Transition */
/* Hover Transition */
/* Pablo */
.create-background {
  background-color: #FDCF56;
  background-image: url("/images/Shell/backgrounds/learning_zone_2_page_bg_create.png");
  background-repeat: repeat;
  background-size: 120px;
  min-height: calc(100vh - 90px); }
  @media screen and (max-width: 1024px) {
    .create-background {
      min-height: calc(100vh - 75px); } }

.play-background, .play-background-solo {
  background-color: #4FD3D7;
  background-image: url("/images/Shell/backgrounds/learning_zone_2_page_bg_play.png");
  background-repeat: repeat;
  background-size: 120px;
  min-height: calc(100vh - 90px); }
  @media screen and (max-width: 1024px) {
    .play-background, .play-background-solo {
      min-height: calc(100vh - 75px); } }

.play-background-browse {
  background-color: #4FD3D7;
  background-image: url("/images/Shell/backgrounds/learning_zone_2_page_bg_play.png");
  background-repeat: repeat;
  background-size: 120px;
  height: 100%; }

.read-background, .read-background-solo {
  background-color: #D299E6;
  background-image: url("/images/Shell/backgrounds/learning_zone_2_page_bg_read.png");
  background-repeat: repeat;
  background-size: 120px;
  min-height: calc(100vh - 90px); }
  @media screen and (max-width: 1024px) {
    .read-background, .read-background-solo {
      min-height: calc(100vh - 75px); } }

.read-background-browse {
  background-color: #D299E6;
  background-image: url("/images/Shell/backgrounds/learning_zone_2_page_bg_read.png");
  background-repeat: repeat;
  background-size: 120px;
  height: 100%; }

.explore-background {
  background-color: #7FD5A0;
  background-image: url("/images/Shell/backgrounds/learning_zone_2_page_bg_explore.png");
  background-repeat: repeat;
  background-size: 120px;
  min-height: calc(100vh - 90px); }
  @media screen and (max-width: 1024px) {
    .explore-background {
      min-height: calc(100vh - 75px); } }

.home-background {
  background-color: #BCE364;
  background-image: url("/images/Shell/backgrounds/learning_zone_2_page_bg_home.png");
  background-repeat: repeat;
  background-size: 120px;
  min-height: calc(100vh - 90px); }
  @media screen and (max-width: 1024px) {
    .home-background {
      min-height: calc(100vh - 75px); } }

.error-background {
  background-color: #E6E6E6;
  background-image: url("/images/Shell/backgrounds/learning_zone_2_page_bg_error.png");
  background-repeat: repeat;
  background-size: 120px;
  min-height: calc(100vh - 90px); }
  @media screen and (max-width: 1024px) {
    .error-background {
      min-height: calc(100vh - 75px); } }

.Header {
  background-color: #01639C;
  margin: 0;
  height: 90px;
  z-index: 10; }
  @media screen and (max-width: 1024px) {
    .Header {
      height: 75px; } }
  .Header button {
    all: unset; }
  .Header .header-nav {
    height: 100%;
    width: 100%;
    max-width: 1280px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: flex-start; }

/*------------------------*/
/*------------------------*/
/*------------------------*/
/*------------------------*/
/* Pablo */
/* Hover Transition */
/* Hover Transition */
/* Pablo */
.create-background {
  background-color: #FDCF56;
  background-image: url("/images/Shell/backgrounds/learning_zone_2_page_bg_create.png");
  background-repeat: repeat;
  background-size: 120px;
  min-height: calc(100vh - 90px); }
  @media screen and (max-width: 1024px) {
    .create-background {
      min-height: calc(100vh - 75px); } }

.play-background, .play-background-solo {
  background-color: #4FD3D7;
  background-image: url("/images/Shell/backgrounds/learning_zone_2_page_bg_play.png");
  background-repeat: repeat;
  background-size: 120px;
  min-height: calc(100vh - 90px); }
  @media screen and (max-width: 1024px) {
    .play-background, .play-background-solo {
      min-height: calc(100vh - 75px); } }

.play-background-browse {
  background-color: #4FD3D7;
  background-image: url("/images/Shell/backgrounds/learning_zone_2_page_bg_play.png");
  background-repeat: repeat;
  background-size: 120px;
  height: 100%; }

.read-background, .read-background-solo {
  background-color: #D299E6;
  background-image: url("/images/Shell/backgrounds/learning_zone_2_page_bg_read.png");
  background-repeat: repeat;
  background-size: 120px;
  min-height: calc(100vh - 90px); }
  @media screen and (max-width: 1024px) {
    .read-background, .read-background-solo {
      min-height: calc(100vh - 75px); } }

.read-background-browse {
  background-color: #D299E6;
  background-image: url("/images/Shell/backgrounds/learning_zone_2_page_bg_read.png");
  background-repeat: repeat;
  background-size: 120px;
  height: 100%; }

.explore-background {
  background-color: #7FD5A0;
  background-image: url("/images/Shell/backgrounds/learning_zone_2_page_bg_explore.png");
  background-repeat: repeat;
  background-size: 120px;
  min-height: calc(100vh - 90px); }
  @media screen and (max-width: 1024px) {
    .explore-background {
      min-height: calc(100vh - 75px); } }

.home-background {
  background-color: #BCE364;
  background-image: url("/images/Shell/backgrounds/learning_zone_2_page_bg_home.png");
  background-repeat: repeat;
  background-size: 120px;
  min-height: calc(100vh - 90px); }
  @media screen and (max-width: 1024px) {
    .home-background {
      min-height: calc(100vh - 75px); } }

.error-background {
  background-color: #E6E6E6;
  background-image: url("/images/Shell/backgrounds/learning_zone_2_page_bg_error.png");
  background-repeat: repeat;
  background-size: 120px;
  min-height: calc(100vh - 90px); }
  @media screen and (max-width: 1024px) {
    .error-background {
      min-height: calc(100vh - 75px); } }

.modal {
  padding: 30px; }
  .modal .modal-content {
    border-radius: 25px; }
  .modal .screen-size {
    text-align: center; }
    .modal .screen-size h1 {
      color: #D63F15; }
    .modal .screen-size p {
      margin-top: 30px;
      padding-bottom: 30px; }

@media screen and (max-height: 400px) {
  .modal .screen-size {
    text-align: center; }
    .modal .screen-size img {
      height: 25vh; }
    .modal .screen-size p {
      margin-top: 30px;
      padding-bottom: 30px; } }

/*------------------------*/
/*------------------------*/
/*------------------------*/
/*------------------------*/
/* Pablo */
/* Hover Transition */
/* Hover Transition */
/* Pablo */
.create-background {
  background-color: #FDCF56;
  background-image: url("/images/Shell/backgrounds/learning_zone_2_page_bg_create.png");
  background-repeat: repeat;
  background-size: 120px;
  min-height: calc(100vh - 90px); }
  @media screen and (max-width: 1024px) {
    .create-background {
      min-height: calc(100vh - 75px); } }

.play-background, .play-background-solo {
  background-color: #4FD3D7;
  background-image: url("/images/Shell/backgrounds/learning_zone_2_page_bg_play.png");
  background-repeat: repeat;
  background-size: 120px;
  min-height: calc(100vh - 90px); }
  @media screen and (max-width: 1024px) {
    .play-background, .play-background-solo {
      min-height: calc(100vh - 75px); } }

.play-background-browse {
  background-color: #4FD3D7;
  background-image: url("/images/Shell/backgrounds/learning_zone_2_page_bg_play.png");
  background-repeat: repeat;
  background-size: 120px;
  height: 100%; }

.read-background, .read-background-solo {
  background-color: #D299E6;
  background-image: url("/images/Shell/backgrounds/learning_zone_2_page_bg_read.png");
  background-repeat: repeat;
  background-size: 120px;
  min-height: calc(100vh - 90px); }
  @media screen and (max-width: 1024px) {
    .read-background, .read-background-solo {
      min-height: calc(100vh - 75px); } }

.read-background-browse {
  background-color: #D299E6;
  background-image: url("/images/Shell/backgrounds/learning_zone_2_page_bg_read.png");
  background-repeat: repeat;
  background-size: 120px;
  height: 100%; }

.explore-background {
  background-color: #7FD5A0;
  background-image: url("/images/Shell/backgrounds/learning_zone_2_page_bg_explore.png");
  background-repeat: repeat;
  background-size: 120px;
  min-height: calc(100vh - 90px); }
  @media screen and (max-width: 1024px) {
    .explore-background {
      min-height: calc(100vh - 75px); } }

.home-background {
  background-color: #BCE364;
  background-image: url("/images/Shell/backgrounds/learning_zone_2_page_bg_home.png");
  background-repeat: repeat;
  background-size: 120px;
  min-height: calc(100vh - 90px); }
  @media screen and (max-width: 1024px) {
    .home-background {
      min-height: calc(100vh - 75px); } }

.error-background {
  background-color: #E6E6E6;
  background-image: url("/images/Shell/backgrounds/learning_zone_2_page_bg_error.png");
  background-repeat: repeat;
  background-size: 120px;
  min-height: calc(100vh - 90px); }
  @media screen and (max-width: 1024px) {
    .error-background {
      min-height: calc(100vh - 75px); } }

.primary-navigation-wrapper {
  padding: 0 25px 0 25px; }
  .primary-navigation-wrapper .primary-navigation {
    display: flex;
    max-width: 650px;
    margin: 0 auto;
    text-align: center;
    flex-flow: row;
    justify-content: space-between;
    text-decoration: none;
    height: 150px; }
    .primary-navigation-wrapper .primary-navigation .primary-navigation--nav-item {
      -webkit-transition: all 0.5s ease-in-out;
      transition: all 0.5s ease-in-out;
      -webkit-transform: translate3d(0);
              transform: translate3d(0);
      margin-top: 25px;
      min-height: 44px;
      min-width: 44px;
      max-height: 103px; }
      .primary-navigation-wrapper .primary-navigation .primary-navigation--nav-item img {
        width: 60%;
        width: 97.5px;
        max-width: 215px;
        max-height: 189px; }
      .primary-navigation-wrapper .primary-navigation .primary-navigation--nav-item .primary-navigation--nav-item--label {
        width: auto;
        margin: 0 auto;
        background-color: #D63F15;
        border-radius: 40px;
        color: #fff;
        font-family: 'Fredoka One', cursive;
        text-align: center;
        text-transform: uppercase;
        top: -20px;
        position: relative;
        border: 3px solid brown;
        display: flex;
        justify-content: center;
        align-items: center; }
      .primary-navigation-wrapper .primary-navigation .primary-navigation--nav-item:hover {
        text-decoration: none;
        -webkit-transform: scale(1.1);
                transform: scale(1.1); }
      .primary-navigation-wrapper .primary-navigation .primary-navigation--nav-item.active {
        -webkit-transform: scale(1.1);
                transform: scale(1.1); }
  .primary-navigation-wrapper.raised {
    position: relative; }
    .primary-navigation-wrapper.raised .primary-navigation--nav-item {
      margin-top: -350px; }

@-webkit-keyframes airDrop {
  from {
    margin-top: -450px; }
  to {
    margin-top: 0px; } }

@keyframes airDrop {
  from {
    margin-top: -450px; }
  to {
    margin-top: 0px; } }

.en-US .primary-navigation-wrapper .primary-navigation {
  max-width: 650px; }
  .en-US .primary-navigation-wrapper .primary-navigation .primary-navigation--nav-item .primary-navigation--nav-item--label {
    font-size: 1.25em;
    height: 35px;
    width: 125px;
    line-height: 1.5em !important; }

.pt-BR .primary-navigation-wrapper .primary-navigation {
  max-width: 750px; }
  .pt-BR .primary-navigation-wrapper .primary-navigation .primary-navigation--nav-item .primary-navigation--nav-item--label {
    font-size: 1em;
    line-height: 1em !important;
    height: 40px;
    width: 150px; }

/*------------------------*/
/*------------------------*/
/*------------------------*/
/*------------------------*/
/* Pablo */
/* Hover Transition */
/* Hover Transition */
/* Pablo */
.create-background {
  background-color: #FDCF56;
  background-image: url("/images/Shell/backgrounds/learning_zone_2_page_bg_create.png");
  background-repeat: repeat;
  background-size: 120px;
  min-height: calc(100vh - 90px); }
  @media screen and (max-width: 1024px) {
    .create-background {
      min-height: calc(100vh - 75px); } }

.play-background, .play-background-solo {
  background-color: #4FD3D7;
  background-image: url("/images/Shell/backgrounds/learning_zone_2_page_bg_play.png");
  background-repeat: repeat;
  background-size: 120px;
  min-height: calc(100vh - 90px); }
  @media screen and (max-width: 1024px) {
    .play-background, .play-background-solo {
      min-height: calc(100vh - 75px); } }

.play-background-browse {
  background-color: #4FD3D7;
  background-image: url("/images/Shell/backgrounds/learning_zone_2_page_bg_play.png");
  background-repeat: repeat;
  background-size: 120px;
  height: 100%; }

.read-background, .read-background-solo {
  background-color: #D299E6;
  background-image: url("/images/Shell/backgrounds/learning_zone_2_page_bg_read.png");
  background-repeat: repeat;
  background-size: 120px;
  min-height: calc(100vh - 90px); }
  @media screen and (max-width: 1024px) {
    .read-background, .read-background-solo {
      min-height: calc(100vh - 75px); } }

.read-background-browse {
  background-color: #D299E6;
  background-image: url("/images/Shell/backgrounds/learning_zone_2_page_bg_read.png");
  background-repeat: repeat;
  background-size: 120px;
  height: 100%; }

.explore-background {
  background-color: #7FD5A0;
  background-image: url("/images/Shell/backgrounds/learning_zone_2_page_bg_explore.png");
  background-repeat: repeat;
  background-size: 120px;
  min-height: calc(100vh - 90px); }
  @media screen and (max-width: 1024px) {
    .explore-background {
      min-height: calc(100vh - 75px); } }

.home-background {
  background-color: #BCE364;
  background-image: url("/images/Shell/backgrounds/learning_zone_2_page_bg_home.png");
  background-repeat: repeat;
  background-size: 120px;
  min-height: calc(100vh - 90px); }
  @media screen and (max-width: 1024px) {
    .home-background {
      min-height: calc(100vh - 75px); } }

.error-background {
  background-color: #E6E6E6;
  background-image: url("/images/Shell/backgrounds/learning_zone_2_page_bg_error.png");
  background-repeat: repeat;
  background-size: 120px;
  min-height: calc(100vh - 90px); }
  @media screen and (max-width: 1024px) {
    .error-background {
      min-height: calc(100vh - 75px); } }

.wrapper {
  margin: 0 25px; }

.sixteen-nine {
  position: relative; }

@media screen and (max-width: 1278px) {
  .sixteen-nine:before {
    display: block;
    content: '';
    width: 100%;
    padding-top: 56.25%; } }

.sixteen-nine > .content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: transparent;
  margin: auto;
  max-width: 1279px;
  max-height: 719.4375px; }
  @media screen and (min-width: 1279px) {
    .sixteen-nine > .content {
      width: 1229px;
      height: 691.3125px;
      display: block;
      position: inherit; } }

.play-background-solo .sixteen-nine > .content, .read-background-solo .sixteen-nine > .content {
  border: solid 4px;
  border-radius: 16px;
  overflow: hidden; }

.play-background-solo .sixteen-nine > .content {
  border-color: #167F9C; }

.read-background-solo .sixteen-nine > .content {
  border-color: #6E52A0;
  background: url("/images/Read/learning_zone_2_read_page_sides.png");
  background-repeat: repeat;
  background-size: 25px 25px; }

footer {
  height: 140px;
  display: flex;
  justify-content: center;
  align-items: center; }
  footer ul {
    margin: 0 auto;
    width: 100%;
    text-align: center;
    list-style: none;
    padding: 0; }
    footer ul li {
      float: left;
      margin-left: 10px; }
  @media screen and (max-width: 1024px) {
    footer {
      height: 100px; } }

button {
  width: 44px; }
  button i {
    font-size: 24px; }

/*------------------------*/
/*------------------------*/
/*------------------------*/
/*------------------------*/
/* Pablo */
/* Hover Transition */
/* Hover Transition */
/* Pablo */
.create-background {
  background-color: #FDCF56;
  background-image: url("/images/Shell/backgrounds/learning_zone_2_page_bg_create.png");
  background-repeat: repeat;
  background-size: 120px;
  min-height: calc(100vh - 90px); }
  @media screen and (max-width: 1024px) {
    .create-background {
      min-height: calc(100vh - 75px); } }

.play-background, .play-background-solo {
  background-color: #4FD3D7;
  background-image: url("/images/Shell/backgrounds/learning_zone_2_page_bg_play.png");
  background-repeat: repeat;
  background-size: 120px;
  min-height: calc(100vh - 90px); }
  @media screen and (max-width: 1024px) {
    .play-background, .play-background-solo {
      min-height: calc(100vh - 75px); } }

.play-background-browse {
  background-color: #4FD3D7;
  background-image: url("/images/Shell/backgrounds/learning_zone_2_page_bg_play.png");
  background-repeat: repeat;
  background-size: 120px;
  height: 100%; }

.read-background, .read-background-solo {
  background-color: #D299E6;
  background-image: url("/images/Shell/backgrounds/learning_zone_2_page_bg_read.png");
  background-repeat: repeat;
  background-size: 120px;
  min-height: calc(100vh - 90px); }
  @media screen and (max-width: 1024px) {
    .read-background, .read-background-solo {
      min-height: calc(100vh - 75px); } }

.read-background-browse {
  background-color: #D299E6;
  background-image: url("/images/Shell/backgrounds/learning_zone_2_page_bg_read.png");
  background-repeat: repeat;
  background-size: 120px;
  height: 100%; }

.explore-background {
  background-color: #7FD5A0;
  background-image: url("/images/Shell/backgrounds/learning_zone_2_page_bg_explore.png");
  background-repeat: repeat;
  background-size: 120px;
  min-height: calc(100vh - 90px); }
  @media screen and (max-width: 1024px) {
    .explore-background {
      min-height: calc(100vh - 75px); } }

.home-background {
  background-color: #BCE364;
  background-image: url("/images/Shell/backgrounds/learning_zone_2_page_bg_home.png");
  background-repeat: repeat;
  background-size: 120px;
  min-height: calc(100vh - 90px); }
  @media screen and (max-width: 1024px) {
    .home-background {
      min-height: calc(100vh - 75px); } }

.error-background {
  background-color: #E6E6E6;
  background-image: url("/images/Shell/backgrounds/learning_zone_2_page_bg_error.png");
  background-repeat: repeat;
  background-size: 120px;
  min-height: calc(100vh - 90px); }
  @media screen and (max-width: 1024px) {
    .error-background {
      min-height: calc(100vh - 75px); } }

.button-transcript {
  background-color: #BA5D00;
  box-shadow: 0px 3px 0px #8C4600;
  border: 3px solid;
  border-color: #8C4600;
  margin-top: 15px;
  border-radius: 8px;
  font-size: 18px;
  color: #fff;
  width: 220px;
  height: 46px;
  text-align: center; }

.media-overlay-figure {
  margin-top: 20px;
  text-align: center; }
  .media-overlay-figure .expand {
    background: #ccc;
    border: 0;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    color: #4D4D4D;
    width: 74px;
    height: 56px;
    margin-left: -74px;
    bottom: 0;
    position: absolute;
    align-self: flex-end;
    cursor: -webkit-zoom-in;
    cursor: zoom-in; }
    .media-overlay-figure .expand:hover {
      color: #333;
      cursor: -webkit-zoom-in;
      cursor: zoom-in; }
  .media-overlay-figure img {
    max-width: 100%;
    margin: 0 auto;
    max-height: 60vh; }
  .media-overlay-figure .fullscreen {
    position: relative; }
  .media-overlay-figure .fullscreen-enabled {
    display: flex;
    align-items: center;
    justify-content: center; }
    .media-overlay-figure .fullscreen-enabled .expand {
      background: rgba(255, 255, 255, 0.8);
      border: 0;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
      color: #808080;
      cursor: -webkit-zoom-out;
      cursor: zoom-out;
      height: 56px;
      width: 74px;
      margin-left: -74px;
      position: inherit; }
      .media-overlay-figure .fullscreen-enabled .expand:hover {
        color: #D96C00;
        cursor: -webkit-zoom-out;
        cursor: zoom-out; }
    .media-overlay-figure .fullscreen-enabled img {
      max-height: 100vh; }

h1.media-overlay--title {
  color: #BA5D00 !important;
  text-align: center !important; }

.media-overlay-container {
  width: 1200px;
  height: auto;
  text-align: center; }
  .media-overlay-container h1 {
    color: #D96C00;
    text-align: center; }
  .media-overlay-container img {
    margin: 0 auto; }
  @media (max-width: 768px) {
    .media-overlay-container {
      max-width: 320px; } }
  @media (min-width: 768px) {
    .media-overlay-container {
      max-width: 640px; } }
  @media (min-width: 1024px) {
    .media-overlay-container {
      max-width: 760px; } }
  @media (min-width: 1280px) {
    .media-overlay-container {
      max-width: 1100px; } }
  @media (min-width: 1600px) {
    .media-overlay-container {
      max-width: 1400px; } }
  .media-overlay-container figcaption {
    font-family: "Open Sans", sans-serif; }
  .media-overlay-container .caption {
    font-family: "Open Sans", sans-serif;
    font-size: 1.25rem;
    color: #666;
    line-height: 24px;
    margin-top: 15px; }
  .media-overlay-container .credit {
    font-family: "Open Sans", sans-serif;
    font-size: 1rem;
    color: #666;
    line-height: 24px;
    font-style: italic; }
  .media-overlay-container .transcript {
    font-family: "Open Sans", sans-serif;
    font-size: 1.25rem;
    color: #666;
    line-height: 24px;
    margin-top: 15px;
    padding: 10px 10px; }

.media-overlay-loading {
  text-align: center; }
  .media-overlay-loading svg {
    margin: 0 auto; }

.media-overlay-head button {
  all: unset;
  float: right;
  color: #4D4D4D; }
  .media-overlay-head button:hover {
    color: #BA5D00; }

.ReactModalPortal .ReactModal__Overlay--after-open {
  background-color: rgba(255, 255, 255, 0.75) !important; }

/*------------------------*/
/*------------------------*/
/*------------------------*/
/*------------------------*/
/* Pablo */
/* Hover Transition */
/* Hover Transition */
/* Pablo */
.create-background {
  background-color: #FDCF56;
  background-image: url("/images/Shell/backgrounds/learning_zone_2_page_bg_create.png");
  background-repeat: repeat;
  background-size: 120px;
  min-height: calc(100vh - 90px); }
  @media screen and (max-width: 1024px) {
    .create-background {
      min-height: calc(100vh - 75px); } }

.play-background, .play-background-solo {
  background-color: #4FD3D7;
  background-image: url("/images/Shell/backgrounds/learning_zone_2_page_bg_play.png");
  background-repeat: repeat;
  background-size: 120px;
  min-height: calc(100vh - 90px); }
  @media screen and (max-width: 1024px) {
    .play-background, .play-background-solo {
      min-height: calc(100vh - 75px); } }

.play-background-browse {
  background-color: #4FD3D7;
  background-image: url("/images/Shell/backgrounds/learning_zone_2_page_bg_play.png");
  background-repeat: repeat;
  background-size: 120px;
  height: 100%; }

.read-background, .read-background-solo {
  background-color: #D299E6;
  background-image: url("/images/Shell/backgrounds/learning_zone_2_page_bg_read.png");
  background-repeat: repeat;
  background-size: 120px;
  min-height: calc(100vh - 90px); }
  @media screen and (max-width: 1024px) {
    .read-background, .read-background-solo {
      min-height: calc(100vh - 75px); } }

.read-background-browse {
  background-color: #D299E6;
  background-image: url("/images/Shell/backgrounds/learning_zone_2_page_bg_read.png");
  background-repeat: repeat;
  background-size: 120px;
  height: 100%; }

.explore-background {
  background-color: #7FD5A0;
  background-image: url("/images/Shell/backgrounds/learning_zone_2_page_bg_explore.png");
  background-repeat: repeat;
  background-size: 120px;
  min-height: calc(100vh - 90px); }
  @media screen and (max-width: 1024px) {
    .explore-background {
      min-height: calc(100vh - 75px); } }

.home-background {
  background-color: #BCE364;
  background-image: url("/images/Shell/backgrounds/learning_zone_2_page_bg_home.png");
  background-repeat: repeat;
  background-size: 120px;
  min-height: calc(100vh - 90px); }
  @media screen and (max-width: 1024px) {
    .home-background {
      min-height: calc(100vh - 75px); } }

.error-background {
  background-color: #E6E6E6;
  background-image: url("/images/Shell/backgrounds/learning_zone_2_page_bg_error.png");
  background-repeat: repeat;
  background-size: 120px;
  min-height: calc(100vh - 90px); }
  @media screen and (max-width: 1024px) {
    .error-background {
      min-height: calc(100vh - 75px); } }

.MercatorPage {
  -webkit-animation: fadeInMap 2s;
          animation: fadeInMap 2s;
  opacity: 0;
  -webkit-animation-delay: 3s;
          animation-delay: 3s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  /*
  SVG Biome Styles
  */
  /*
   Animation Keyframes 
  */ }
  .MercatorPage .animate_locales_out {
    -webkit-animation: fadeOut 1s;
            animation: fadeOut 1s;
    -webkit-animation-iteration-count: 1;
            animation-iteration-count: 1;
    fill-opacity: 1;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards; }
  .MercatorPage .animate_locales_in {
    -webkit-animation: fadeIn 1s;
            animation: fadeIn 1s;
    -webkit-animation-iteration-count: 1;
            animation-iteration-count: 1;
    fill-opacity: 1;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards; }
  .MercatorPage .animate_pins {
    -webkit-animation: pinOut 0.5s;
            animation: pinOut 0.5s;
    -webkit-animation-iteration-count: 1;
            animation-iteration-count: 1;
    fill-opacity: 1;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards; }
  .MercatorPage .animate_pins_in {
    -webkit-animation: pinIns 1s;
            animation: pinIns 1s;
    -webkit-animation-iteration-count: 1;
            animation-iteration-count: 1;
    fill-opacity: 0;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards; }
  .MercatorPage .biome-navigation {
    position: absolute;
    height: 80%;
    margin-top: 2%; }
    .MercatorPage .biome-navigation .tab-list-flex {
      list-style: none;
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: space-between;
      margin: 0;
      padding: 0;
      width: 65px; }
    .MercatorPage .biome-navigation .button-label {
      margin-left: 7px; }
      @media screen and (max-width: 1220px) {
        .MercatorPage .biome-navigation .button-label {
          display: none; } }
    .MercatorPage .biome-navigation button {
      -webkit-transition: all 0.5s;
      transition: all 0.5s;
      -webkit-transform: scale(1);
              transform: scale(1);
      border-radius: 8px;
      font-size: 18px;
      box-shadow: 0px 3px 0px #3A5D13;
      background-color: #fff;
      border: 3px solid #3A5D13;
      color: #333333;
      width: 220px;
      height: 46px !important;
      text-align: left; }
      .MercatorPage .biome-navigation button svg,
      .MercatorPage .biome-navigation button .svg-inline--fa {
        width: 1.325em !important;
        height: 100%;
        vertical-align: -0.65em; }
      .MercatorPage .biome-navigation button .svg-inline--fa {
        margin-left: 5px !important; }
        @media screen and (max-width: 1220px) {
          .MercatorPage .biome-navigation button .svg-inline--fa {
            margin-left: 0 !important; } }
      @media screen and (max-width: 1220px) {
        .MercatorPage .biome-navigation button {
          width: 44px;
          margin: 0 auto;
          text-align: center;
          padding: 0 5px; } }
      .MercatorPage .biome-navigation button.desert {
        border-color: #a66d19;
        box-shadow: 0px 3px 0px #a66d19; }
      .MercatorPage .biome-navigation button.desert.active, .MercatorPage .biome-navigation button.desert:hover, .MercatorPage .biome-navigation button.desert:active {
        background-color: #ffa827;
        box-shadow: 0px 3px 0px #a66d19;
        border: 3px solid;
        border-color: #a66d19;
        -webkit-transform: scale(1.1);
                transform: scale(1.1);
        -webkit-transition: all 0.5s;
        transition: all 0.5s; }
      .MercatorPage .biome-navigation button.grassland {
        border-color: #8c8331;
        box-shadow: 0px 3px 0px #8c8331; }
      .MercatorPage .biome-navigation button.grassland.active, .MercatorPage .biome-navigation button.grassland:hover, .MercatorPage .biome-navigation button.grassland:active {
        background-color: #ffee58;
        box-shadow: 0px 3px 0px #8c8331;
        border: 3px solid;
        border-color: #8c8331;
        -webkit-transform: scale(1.1);
                transform: scale(1.1);
        -webkit-transition: all 0.5s;
        transition: all 0.5s; }
      .MercatorPage .biome-navigation button.tropicalForest {
        border-color: #1F5422;
        box-shadow: 0px 3px 0px #1F5422; }
      .MercatorPage .biome-navigation button.tropicalForest.active, .MercatorPage .biome-navigation button.tropicalForest:hover, .MercatorPage .biome-navigation button.tropicalForest:active {
        color: #fff;
        background-color: #328736;
        box-shadow: 0px 3px 0px #1F5422;
        border: 3px solid;
        border-color: #1F5422;
        -webkit-transform: scale(1.1);
                transform: scale(1.1);
        -webkit-transition: all 0.5s;
        transition: all 0.5s; }
      .MercatorPage .biome-navigation button.temperateForest {
        border-color: #628040;
        box-shadow: 0px 3px 0px #628040; }
      .MercatorPage .biome-navigation button.temperateForest.active, .MercatorPage .biome-navigation button.temperateForest:hover, .MercatorPage .biome-navigation button.temperateForest:active {
        background-color: #9ccc66;
        box-shadow: 0px 3px 0px #628040;
        border: 3px solid;
        border-color: #628040;
        -webkit-transform: scale(1.1);
                transform: scale(1.1);
        -webkit-transition: all 0.5s;
        transition: all 0.5s; }
      .MercatorPage .biome-navigation button.taiga {
        border-color: #4C3C69;
        box-shadow: 0px 3px 0px #4C3C69; }
      .MercatorPage .biome-navigation button.taiga.active, .MercatorPage .biome-navigation button.taiga:hover, .MercatorPage .biome-navigation button.taiga:active {
        color: #fff;
        background-color: #8467B5;
        box-shadow: 0px 3px 0px #4C3C69;
        border: 3px solid;
        border-color: #4C3C69;
        -webkit-transform: scale(1.1);
                transform: scale(1.1);
        -webkit-transition: all 0.5s;
        transition: all 0.5s; }
      .MercatorPage .biome-navigation button.tundra {
        border-color: #2C536E;
        box-shadow: 0px 3px 0px #2C536E; }
      .MercatorPage .biome-navigation button.tundra.active, .MercatorPage .biome-navigation button.tundra:hover, .MercatorPage .biome-navigation button.tundra:hover {
        color: #fff;
        background-color: #407AA1;
        box-shadow: 0px 3px 0px #2C536E;
        border: 3px solid;
        border-color: #2C536E;
        -webkit-transform: scale(1.1);
                transform: scale(1.1);
        -webkit-transition: all 0.5s;
        transition: all 0.5s; }
  .MercatorPage .biome-spotlight-feature {
    position: absolute;
    right: 12%;
    bottom: 10%;
    padding: 10px;
    width: 222px;
    height: 140px;
    background: transparent !important; }
    @media only screen and (max-width: 768px) {
      .MercatorPage .biome-spotlight-feature {
        right: 10%; } }
    .MercatorPage .biome-spotlight-feature.viewed {
      -webkit-transform: scale(0.7);
              transform: scale(0.7); }
    .MercatorPage .biome-spotlight-feature .biome-spotlight-feature--btn {
      border-radius: 8px;
      padding: 10px 10px 2px 10px;
      width: auto;
      height: auto;
      text-align: center;
      box-shadow: 0px 3px 0px gray;
      background-color: #cccccc;
      border: 3px solid gray; }
    .MercatorPage .biome-spotlight-feature.desert .biome-spotlight-feature--btn {
      background-color: #ffa827;
      box-shadow: 0px 3px 0px #a66d19;
      border: 3px solid;
      border-color: #a66d19;
      -webkit-transform: scale(1.1);
              transform: scale(1.1);
      -webkit-transition: all 0.5s;
      transition: all 0.5s; }
    .MercatorPage .biome-spotlight-feature.grassland .biome-spotlight-feature--btn {
      background-color: #ffee58;
      box-shadow: 0px 3px 0px #8c8331;
      border: 3px solid;
      border-color: #8c8331;
      -webkit-transform: scale(1.1);
              transform: scale(1.1);
      -webkit-transition: all 0.5s;
      transition: all 0.5s; }
    .MercatorPage .biome-spotlight-feature.tropicalForest .biome-spotlight-feature--btn {
      background-color: #328736;
      box-shadow: 0px 3px 0px #1F5422;
      border: 3px solid;
      border-color: #1F5422;
      -webkit-transform: scale(1.1);
              transform: scale(1.1);
      -webkit-transition: all 0.5s;
      transition: all 0.5s; }
    .MercatorPage .biome-spotlight-feature.temperateForest .biome-spotlight-feature--btn {
      background-color: #9ccc66;
      box-shadow: 0px 3px 0px #628040;
      border: 3px solid;
      border-color: #628040;
      -webkit-transform: scale(1.1);
              transform: scale(1.1);
      -webkit-transition: all 0.5s;
      transition: all 0.5s; }
    .MercatorPage .biome-spotlight-feature.taiga .biome-spotlight-feature--btn {
      background-color: #8467B5;
      box-shadow: 0px 3px 0px #4C3C69;
      border: 3px solid;
      border-color: #4C3C69;
      -webkit-transform: scale(1.1);
              transform: scale(1.1);
      -webkit-transition: all 0.5s;
      transition: all 0.5s; }
    .MercatorPage .biome-spotlight-feature.tundra .biome-spotlight-feature--btn {
      color: #fff;
      background-color: #407AA1;
      box-shadow: 0px 3px 0px #2C536E;
      border: 3px solid;
      border-color: #2C536E;
      -webkit-transform: scale(1.1);
              transform: scale(1.1);
      -webkit-transition: all 0.5s;
      transition: all 0.5s; }
  .MercatorPage .biome-spotlight-feature--label {
    text-align: center;
    height: 20px;
    margin-bottom: 10px;
    color: #333333; }
    @media screen and (min-width: 1220px) {
      .MercatorPage .biome-spotlight-feature--label {
        visibility: hidden; } }
  .MercatorPage .biome-spotlight-feature--sub-label {
    text-align: center;
    margin-top: 0;
    line-height: 1.25; }
  .MercatorPage .biome-spotlight-feature--content-box {
    color: #fff;
    margin: 0 auto;
    opacity: 1;
    height: 101.25px;
    width: 180px; }
  .MercatorPage .biome-spotlight-feature--content-box--loading {
    color: #fff;
    margin: 0 auto;
    opacity: 1; }
  .MercatorPage .biome-spotlight-feature--content-box--image-box {
    width: 100%;
    height: 100%;
    position: relative;
    margin: 0 auto; }
  .MercatorPage .biome-spotlight-feature--content-box--image-box--icon svg {
    color: #fff;
    font-size: 32px;
    margin: 0 auto;
    text-align: center;
    opacity: 1;
    position: absolute;
    z-index: 2;
    top: 30%;
    left: 45%; }
  .MercatorPage .biome-spotlight-feature--content-box--image-box--image {
    width: 100%;
    height: 100%; }
  .MercatorPage .pins,
  .MercatorPage .box {
    fill: none;
    fill-opacity: 0;
    stroke: none;
    display: none; }
  .MercatorPage #svg .box,
  .MercatorPage #svg .box .pin {
    display: none; }
  .MercatorPage svg #map_pin path {
    fill: #cc0000;
    stroke-width: 2px;
    stroke: #fff; }
  .MercatorPage #desert_boxes > *,
  .MercatorPage #tundra_boxes > *,
  .MercatorPage #taiga_boxes > *,
  .MercatorPage #temp_forest_boxes > *,
  .MercatorPage #trop_forest_boxes > *,
  .MercatorPage #grassland_boxes > * {
    cursor: pointer; }
  .MercatorPage #neatline {
    stroke: #48731B;
    stroke-width: 3.5px;
    fill: none; }
  .MercatorPage .box.active {
    display: block; }
  .MercatorPage .desert {
    fill: #ffa827;
    background-color: #ffa827; }
  .MercatorPage .grassland {
    fill: #ffee58;
    background-color: #ffee58; }
  .MercatorPage .temp_forest,
  .MercatorPage .temperateForest {
    fill: #9ccc66;
    background-color: #9ccc66; }
  .MercatorPage .trop_forest,
  .MercatorPage .tropicalForest {
    fill: #328736;
    background-color: #328736;
    color: #fff; }
  .MercatorPage .taiga {
    fill: #8467B5;
    background-color: #8467B5;
    color: #fff; }
  .MercatorPage .tundra {
    fill: #407AA1;
    background-color: #407AA1; }
  .MercatorPage .water {
    fill: #b3e5fc;
    background-color: #b3e5fc; }
  .MercatorPage .generic {
    fill: #e6e6e6;
    background-color: #e6e6e6; }

@-webkit-keyframes fadeIn {
  from {
    fill-opacity: 0.35; }
  to {
    fill-opacity: 1; } }

@keyframes fadeIn {
  from {
    fill-opacity: 0.35; }
  to {
    fill-opacity: 1; } }

@-webkit-keyframes fadeOut {
  from {
    fill-opacity: 1; }
  to {
    fill-opacity: 0.35; } }

@keyframes fadeOut {
  from {
    fill-opacity: 1; }
  to {
    fill-opacity: 0.35; } }

@-webkit-keyframes pinIns {
  from {
    fill-opacity: 0;
    display: block; }
  to {
    fill-opacity: 1; } }

@keyframes pinIns {
  from {
    fill-opacity: 0;
    display: block; }
  to {
    fill-opacity: 1; } }

@-webkit-keyframes pinOut {
  from {
    fill-opacity: 1; }
  to {
    fill-opacity: 0;
    display: none; } }

@keyframes pinOut {
  from {
    fill-opacity: 1; }
  to {
    fill-opacity: 0;
    display: none; } }

@-webkit-keyframes delayedPulse {
  from {
    -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1); }
  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
            transform: scale3d(1.05, 1.05, 1.05); }
  to {
    -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1); } }

@keyframes delayedPulse {
  from {
    -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1); }
  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
            transform: scale3d(1.05, 1.05, 1.05); }
  to {
    -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1); } }
  .MercatorPage .delayed-pulse {
    -webkit-animation-name: delayedPulese;
            animation-name: delayedPulese;
    -webkit-animation-delay: 3s;
            animation-delay: 3s; }

.MercatorPageSplash {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 16px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #a8e2bc;
  -webkit-animation: fadeOutIntro 1s ease-out;
          animation: fadeOutIntro 1s ease-out;
  -webkit-animation-delay: 3s;
          animation-delay: 3s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  border: 4px solid #48731B; }
  .MercatorPageSplash h1 {
    width: 50%;
    color: #BA5D00;
    font-size: 60px;
    line-height: 72px; }
  .MercatorPageSplash .loading:after {
    color: #BA5D00;
    font-size: 60px;
    line-height: 72px;
    overflow: hidden;
    display: inline-block;
    vertical-align: bottom;
    -webkit-animation: ellipsis steps(4, end) 900ms infinite;
            animation: ellipsis steps(4, end) 900ms infinite;
    content: "\2026";
    /* ascii code for the ellipsis character */
    width: 0px; }

@-webkit-keyframes fadeOutIntro {
  0% {
    opactiy: 1; }
  99% {
    opacity: 0; }
  100% {
    z-index: -2;
    opacity: 0;
    display: none !important; } }

@keyframes fadeOutIntro {
  0% {
    opactiy: 1; }
  99% {
    opacity: 0; }
  100% {
    z-index: -2;
    opacity: 0;
    display: none !important; } }

@-webkit-keyframes fadeInMap {
  0% {
    opacity: 0; }
  100% {
    opacity: 1;
    display: flex; } }

@keyframes fadeInMap {
  0% {
    opacity: 0; }
  100% {
    opacity: 1;
    display: flex; } }

@keyframes ellipsis {
  to {
    width: 1.25em; } }

@-webkit-keyframes ellipsis {
  to {
    width: 1.25em; } }

/*------------------------*/
/*------------------------*/
/*------------------------*/
/*------------------------*/
/* Pablo */
/* Hover Transition */
/* Hover Transition */
/* Pablo */
.create-background {
  background-color: #FDCF56;
  background-image: url("/images/Shell/backgrounds/learning_zone_2_page_bg_create.png");
  background-repeat: repeat;
  background-size: 120px;
  min-height: calc(100vh - 90px); }
  @media screen and (max-width: 1024px) {
    .create-background {
      min-height: calc(100vh - 75px); } }

.play-background, .play-background-solo {
  background-color: #4FD3D7;
  background-image: url("/images/Shell/backgrounds/learning_zone_2_page_bg_play.png");
  background-repeat: repeat;
  background-size: 120px;
  min-height: calc(100vh - 90px); }
  @media screen and (max-width: 1024px) {
    .play-background, .play-background-solo {
      min-height: calc(100vh - 75px); } }

.play-background-browse {
  background-color: #4FD3D7;
  background-image: url("/images/Shell/backgrounds/learning_zone_2_page_bg_play.png");
  background-repeat: repeat;
  background-size: 120px;
  height: 100%; }

.read-background, .read-background-solo {
  background-color: #D299E6;
  background-image: url("/images/Shell/backgrounds/learning_zone_2_page_bg_read.png");
  background-repeat: repeat;
  background-size: 120px;
  min-height: calc(100vh - 90px); }
  @media screen and (max-width: 1024px) {
    .read-background, .read-background-solo {
      min-height: calc(100vh - 75px); } }

.read-background-browse {
  background-color: #D299E6;
  background-image: url("/images/Shell/backgrounds/learning_zone_2_page_bg_read.png");
  background-repeat: repeat;
  background-size: 120px;
  height: 100%; }

.explore-background {
  background-color: #7FD5A0;
  background-image: url("/images/Shell/backgrounds/learning_zone_2_page_bg_explore.png");
  background-repeat: repeat;
  background-size: 120px;
  min-height: calc(100vh - 90px); }
  @media screen and (max-width: 1024px) {
    .explore-background {
      min-height: calc(100vh - 75px); } }

.home-background {
  background-color: #BCE364;
  background-image: url("/images/Shell/backgrounds/learning_zone_2_page_bg_home.png");
  background-repeat: repeat;
  background-size: 120px;
  min-height: calc(100vh - 90px); }
  @media screen and (max-width: 1024px) {
    .home-background {
      min-height: calc(100vh - 75px); } }

.error-background {
  background-color: #E6E6E6;
  background-image: url("/images/Shell/backgrounds/learning_zone_2_page_bg_error.png");
  background-repeat: repeat;
  background-size: 120px;
  min-height: calc(100vh - 90px); }
  @media screen and (max-width: 1024px) {
    .error-background {
      min-height: calc(100vh - 75px); } }

.PabloPage {
  min-height: 100%;
  max-height: 100%;
  height: 100%;
  border: 4px solid #db8500;
  border-radius: 16px; }
  .PabloPage .pablo-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    height: 100%; }
  .PabloPage #stickerbook_container {
    height: 100%;
    background-color: #FFFFFF;
    position: absolute; }
  .PabloPage #colorsCont, .PabloPage .controlsCont, .PabloPage #stickersCont, .PabloPage #backgroundsCont {
    top: 0;
    left: 0;
    position: relative;
    background-color: #F9E9A6;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    padding: 0 10px 0 10px;
    overflow: hidden; }
  .PabloPage .controlsCont {
    height: 100%;
    z-index: 1000; }
  .PabloPage .drawerCont {
    -webkit-animation-duration: .5s;
            animation-duration: .5s; }
  .PabloPage #rightDrawerCont {
    padding-left: 6px; }
  .PabloPage #leftDrawerCont {
    padding-right: 6px; }
  .PabloPage #colorsCont, .PabloPage #stickersCont, .PabloPage #backgroundsCont {
    border: 2px solid #db8500;
    border-radius: 8px;
    height: calc(100% - 24px);
    margin-top: 12px;
    padding: 0 12px 0 12px; }
    @media (max-width: 1200px) {
      .PabloPage #colorsCont, .PabloPage #stickersCont, .PabloPage #backgroundsCont {
        height: 100%;
        margin-top: 0; } }
  .PabloPage #backgroundsCont {
    padding: 0 20px 0 20px; }
  .PabloPage #stickersCont {
    padding: 10px 12px 10px 12px; }
  .PabloPage #arrow-right {
    position: absolute;
    width: 0;
    height: 0;
    border-top: 19px solid transparent;
    border-bottom: 19px solid transparent;
    border-left: 30px solid #db8500; }
  .PabloPage #arrow-right::after {
    content: '';
    width: 0;
    height: 0;
    border-top: 19px solid transparent;
    border-bottom: 19px solid transparent;
    border-left: 30px solid #F9E9A6;
    position: absolute;
    margin-top: -19px;
    margin-left: -34px; }
  .PabloPage #arrow-left {
    position: absolute;
    width: 0;
    height: 0;
    border-top: 19px solid transparent;
    border-bottom: 19px solid transparent;
    border-right: 30px solid #db8500; }
  .PabloPage #arrow-left::after {
    content: '';
    width: 0;
    height: 0;
    border-top: 19px solid transparent;
    border-bottom: 19px solid transparent;
    border-right: 30px solid #F9E9A6;
    position: absolute;
    margin-top: -19px;
    margin-left: 4px; }
  .PabloPage .rightToolbarContainer, .PabloPage .leftToolbarContainer {
    position: absolute;
    z-index: 1000;
    display: flex;
    height: 100%;
    overflow: hidden; }
  .PabloPage #leftControlsCont {
    padding-top: 80px;
    border-right: 2px solid #db8500; }
  .PabloPage #rightControlsCont {
    border-left: 2px solid #db8500; }
  .PabloPage .rightToolbarContainer {
    right: 0;
    border-radius: 0 10px 10px 0; }
  .PabloPage .leftToolbarContainer {
    left: 0;
    border-radius: 10px 0 0 10px; }
  .PabloPage button {
    all: unset; }
    .PabloPage button:focus {
      outline: 5px auto -webkit-focus-ring-color; }
  .PabloPage .hideDrawer {
    display: none; }
  @media (max-width: 900px) {
    .PabloPage #stickersCont .col {
      padding: 0 10px 0 10px; } }
  .PabloPage #backgroundsCont .col {
    padding: 0 20px 0 20px; }
  .PabloPage .hide {
    display: none !important; }
  .PabloPage .controlsMarginLeft {
    margin-left: 32px; }
  .PabloPage .controlsMarginRight {
    margin-right: 32px; }
  .PabloPage .active {
    -webkit-transform: scale(1.3);
            transform: scale(1.3); }
    @media (max-width: 1200px) {
      .PabloPage .active {
        -webkit-transform: scale(1.15);
                transform: scale(1.15); } }

@media print {
  body.print {
    background-color: transparent;
    background-image: none; }
    body.print footer, body.print header, body.print .primary-navigation-wrapper, body.print .toolbarContainer, body.print .SelectionWindowComponent {
      display: none;
      visibility: hidden; }
    body.print .PabloPage {
      border: none; } }

/*------------------------*/
/*------------------------*/
/*------------------------*/
/*------------------------*/
/* Pablo */
/* Hover Transition */
/* Hover Transition */
/* Pablo */
.create-background {
  background-color: #FDCF56;
  background-image: url("/images/Shell/backgrounds/learning_zone_2_page_bg_create.png");
  background-repeat: repeat;
  background-size: 120px;
  min-height: calc(100vh - 90px); }
  @media screen and (max-width: 1024px) {
    .create-background {
      min-height: calc(100vh - 75px); } }

.play-background, .play-background-solo {
  background-color: #4FD3D7;
  background-image: url("/images/Shell/backgrounds/learning_zone_2_page_bg_play.png");
  background-repeat: repeat;
  background-size: 120px;
  min-height: calc(100vh - 90px); }
  @media screen and (max-width: 1024px) {
    .play-background, .play-background-solo {
      min-height: calc(100vh - 75px); } }

.play-background-browse {
  background-color: #4FD3D7;
  background-image: url("/images/Shell/backgrounds/learning_zone_2_page_bg_play.png");
  background-repeat: repeat;
  background-size: 120px;
  height: 100%; }

.read-background, .read-background-solo {
  background-color: #D299E6;
  background-image: url("/images/Shell/backgrounds/learning_zone_2_page_bg_read.png");
  background-repeat: repeat;
  background-size: 120px;
  min-height: calc(100vh - 90px); }
  @media screen and (max-width: 1024px) {
    .read-background, .read-background-solo {
      min-height: calc(100vh - 75px); } }

.read-background-browse {
  background-color: #D299E6;
  background-image: url("/images/Shell/backgrounds/learning_zone_2_page_bg_read.png");
  background-repeat: repeat;
  background-size: 120px;
  height: 100%; }

.explore-background {
  background-color: #7FD5A0;
  background-image: url("/images/Shell/backgrounds/learning_zone_2_page_bg_explore.png");
  background-repeat: repeat;
  background-size: 120px;
  min-height: calc(100vh - 90px); }
  @media screen and (max-width: 1024px) {
    .explore-background {
      min-height: calc(100vh - 75px); } }

.home-background {
  background-color: #BCE364;
  background-image: url("/images/Shell/backgrounds/learning_zone_2_page_bg_home.png");
  background-repeat: repeat;
  background-size: 120px;
  min-height: calc(100vh - 90px); }
  @media screen and (max-width: 1024px) {
    .home-background {
      min-height: calc(100vh - 75px); } }

.error-background {
  background-color: #E6E6E6;
  background-image: url("/images/Shell/backgrounds/learning_zone_2_page_bg_error.png");
  background-repeat: repeat;
  background-size: 120px;
  min-height: calc(100vh - 90px); }
  @media screen and (max-width: 1024px) {
    .error-background {
      min-height: calc(100vh - 75px); } }

.DrawerButtonComponent .colorBtn {
  width: 44px;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center; }
  .DrawerButtonComponent .colorBtn:hover {
    cursor: pointer;
    -webkit-transform: scale(1.3);
            transform: scale(1.3);
    -webkit-transition: -webkit-transform 0.3s ease-in-out;
    transition: -webkit-transform 0.3s ease-in-out;
    transition: transform 0.3s ease-in-out;
    transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out; }
    @media (max-width: 1200px) {
      .DrawerButtonComponent .colorBtn:hover {
        -webkit-transform: scale(1.15);
                transform: scale(1.15);
        -webkit-transition: -webkit-transform 0.3s ease-in-out;
        transition: -webkit-transform 0.3s ease-in-out;
        transition: transform 0.3s ease-in-out;
        transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out; } }

.DrawerButtonComponent .color {
  width: 44px;
  height: 44px;
  border-radius: 50%; }
  @media (max-width: 1200px) {
    .DrawerButtonComponent .color {
      width: 39px;
      height: 39px; } }

.DrawerButtonComponent .background:hover {
  cursor: pointer;
  -webkit-transform: scale(1.3);
          transform: scale(1.3);
  -webkit-transition: -webkit-transform 0.3s ease-in-out;
  transition: -webkit-transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out; }
  @media (max-width: 900px) {
    .DrawerButtonComponent .background:hover {
      -webkit-transform: scale(1.15);
              transform: scale(1.15);
      -webkit-transition: -webkit-transform 0.3s ease-in-out;
      transition: -webkit-transform 0.3s ease-in-out;
      transition: transform 0.3s ease-in-out;
      transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out; } }

.DrawerButtonComponent .activeSticker {
  -webkit-transform: scale(1.1);
          transform: scale(1.1); }
  @media (max-width: 900px) {
    .DrawerButtonComponent .activeSticker {
      -webkit-transform: scale(1.05);
              transform: scale(1.05); } }

.DrawerButtonComponent .activeBackground {
  -webkit-transform: scale(1.3);
          transform: scale(1.3); }
  @media (max-width: 900px) {
    .DrawerButtonComponent .activeBackground {
      -webkit-transform: scale(1.15);
              transform: scale(1.15); } }

.DrawerButtonComponent .activeColor {
  -webkit-transform: scale(1.3);
          transform: scale(1.3); }
  @media (max-width: 1200px) {
    .DrawerButtonComponent .activeColor {
      -webkit-transform: scale(1.15);
              transform: scale(1.15); } }

.DrawerButtonComponent .sticker:hover {
  cursor: pointer;
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
  -webkit-transition: -webkit-transform 0.3s ease-in-out;
  transition: -webkit-transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out; }
  @media (max-width: 900px) {
    .DrawerButtonComponent .sticker:hover {
      -webkit-transform: scale(1.05);
              transform: scale(1.05);
      -webkit-transition: -webkit-transform 0.3s ease-in-out;
      transition: -webkit-transform 0.3s ease-in-out;
      transition: transform 0.3s ease-in-out;
      transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out; } }

.DrawerButtonComponent .drawerImage, .DrawerButtonComponent .background, .DrawerButtonComponent .sticker {
  display: flex;
  justify-content: center;
  align-items: center; }

.DrawerButtonComponent .background {
  width: 100px;
  height: 100px; }
  @media (max-width: 1100px) {
    .DrawerButtonComponent .background {
      height: 80px;
      width: 80px; } }
  .DrawerButtonComponent .background .drawerImage {
    width: 100px;
    height: 100px; }
    @media (max-width: 1100px) {
      .DrawerButtonComponent .background .drawerImage {
        height: 80px;
        width: 80px; } }

.DrawerButtonComponent .sticker {
  width: 100px;
  height: 100px; }
  @media (max-width: 1100px) {
    .DrawerButtonComponent .sticker {
      height: 80px;
      width: 80px; } }
  .DrawerButtonComponent .sticker .drawerImage {
    width: 100px;
    height: 100px; }
    @media (max-width: 1100px) {
      .DrawerButtonComponent .sticker .drawerImage {
        height: 80px;
        width: 80px; } }
    @media (max-width: 900px) {
      .DrawerButtonComponent .sticker .drawerImage {
        height: 70px;
        width: 70px; } }
    @media (max-width: 800px) {
      .DrawerButtonComponent .sticker .drawerImage {
        height: 60px;
        width: 60px; } }

.DrawerButtonComponent .sixteen-nine-ratio {
  height: 56.25px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #c6a20d;
  background-color: #FFFFFF; }
  @media (max-width: 1100px) {
    .DrawerButtonComponent .sixteen-nine-ratio {
      height: 45px; } }

/*------------------------*/
/*------------------------*/
/*------------------------*/
/*------------------------*/
/* Pablo */
/* Hover Transition */
/* Hover Transition */
/* Pablo */
.create-background {
  background-color: #FDCF56;
  background-image: url("/images/Shell/backgrounds/learning_zone_2_page_bg_create.png");
  background-repeat: repeat;
  background-size: 120px;
  min-height: calc(100vh - 90px); }
  @media screen and (max-width: 1024px) {
    .create-background {
      min-height: calc(100vh - 75px); } }

.play-background, .play-background-solo {
  background-color: #4FD3D7;
  background-image: url("/images/Shell/backgrounds/learning_zone_2_page_bg_play.png");
  background-repeat: repeat;
  background-size: 120px;
  min-height: calc(100vh - 90px); }
  @media screen and (max-width: 1024px) {
    .play-background, .play-background-solo {
      min-height: calc(100vh - 75px); } }

.play-background-browse {
  background-color: #4FD3D7;
  background-image: url("/images/Shell/backgrounds/learning_zone_2_page_bg_play.png");
  background-repeat: repeat;
  background-size: 120px;
  height: 100%; }

.read-background, .read-background-solo {
  background-color: #D299E6;
  background-image: url("/images/Shell/backgrounds/learning_zone_2_page_bg_read.png");
  background-repeat: repeat;
  background-size: 120px;
  min-height: calc(100vh - 90px); }
  @media screen and (max-width: 1024px) {
    .read-background, .read-background-solo {
      min-height: calc(100vh - 75px); } }

.read-background-browse {
  background-color: #D299E6;
  background-image: url("/images/Shell/backgrounds/learning_zone_2_page_bg_read.png");
  background-repeat: repeat;
  background-size: 120px;
  height: 100%; }

.explore-background {
  background-color: #7FD5A0;
  background-image: url("/images/Shell/backgrounds/learning_zone_2_page_bg_explore.png");
  background-repeat: repeat;
  background-size: 120px;
  min-height: calc(100vh - 90px); }
  @media screen and (max-width: 1024px) {
    .explore-background {
      min-height: calc(100vh - 75px); } }

.home-background {
  background-color: #BCE364;
  background-image: url("/images/Shell/backgrounds/learning_zone_2_page_bg_home.png");
  background-repeat: repeat;
  background-size: 120px;
  min-height: calc(100vh - 90px); }
  @media screen and (max-width: 1024px) {
    .home-background {
      min-height: calc(100vh - 75px); } }

.error-background {
  background-color: #E6E6E6;
  background-image: url("/images/Shell/backgrounds/learning_zone_2_page_bg_error.png");
  background-repeat: repeat;
  background-size: 120px;
  min-height: calc(100vh - 90px); }
  @media screen and (max-width: 1024px) {
    .error-background {
      min-height: calc(100vh - 75px); } }

.SelectionWindowComponent {
  display: inline-block;
  position: relative;
  z-index: 1010;
  position: absolute;
  top: 0;
  background-color: #DB8500;
  clip-path: circle(76.2% at 24% 22%);
  -webkit-clip-path: circle(76.2% at 24% 22%);
  width: 80px;
  height: 80px;
  border-radius: 10px 0 0 0; }
  .SelectionWindowComponent .stickerSelection {
    width: 60px;
    height: 60px;
    margin: 5px 0 0 2px; }
  .SelectionWindowComponent .eraserSelection, .SelectionWindowComponent .backgroundSelection {
    width: 44px;
    height: 44px;
    margin: 8px 0 0 8px;
    color: #4D4D4D; }
  .SelectionWindowComponent .eraserSelection:hover, .SelectionWindowComponent .backgroundSelection:hover, .SelectionWindowComponent .stickerSelection:hover, .SelectionWindowComponent .selectionWindowIcon:hover {
    cursor: url("/images/Pablo/cursors/ban-solid.svg"), default; }
  .SelectionWindowComponent .brushSelectionWindow {
    border-radius: 10px 0 0 0;
    position: absolute;
    z-index: 1010;
    top: 0px;
    /* equal to border thickness */
    left: 0px;
    /* equal to border thickness */
    width: 76px;
    /* container height - (border thickness * 2) */
    height: 76px;
    /* container height - (border thickness * 2) */
    clip-path: circle(76.2% at 24% 22%);
    -webkit-clip-path: circle(76.2% at 24% 22%);
    overflow: hidden; }
  .SelectionWindowComponent .selectionWindowIcon {
    font-size: 75px;
    position: absolute; }
  .SelectionWindowComponent .selectionBrushIcon {
    font-size: 70px;
    top: -14px;
    left: 12px; }
  .SelectionWindowComponent .selectionPencilIcon {
    font-size: 70px;
    top: -14px;
    left: 16px; }
  .SelectionWindowComponent .selectionSprayIcon {
    font-size: 65px;
    top: 10px;
    left: -4px; }
  .SelectionWindowComponent .selectionFillIcon {
    font-size: 65px;
    top: -10px;
    left: -16px; }

/*------------------------*/
/*------------------------*/
/*------------------------*/
/*------------------------*/
/* Pablo */
/* Hover Transition */
/* Hover Transition */
/* Pablo */
.create-background {
  background-color: #FDCF56;
  background-image: url("/images/Shell/backgrounds/learning_zone_2_page_bg_create.png");
  background-repeat: repeat;
  background-size: 120px;
  min-height: calc(100vh - 90px); }
  @media screen and (max-width: 1024px) {
    .create-background {
      min-height: calc(100vh - 75px); } }

.play-background, .play-background-solo {
  background-color: #4FD3D7;
  background-image: url("/images/Shell/backgrounds/learning_zone_2_page_bg_play.png");
  background-repeat: repeat;
  background-size: 120px;
  min-height: calc(100vh - 90px); }
  @media screen and (max-width: 1024px) {
    .play-background, .play-background-solo {
      min-height: calc(100vh - 75px); } }

.play-background-browse {
  background-color: #4FD3D7;
  background-image: url("/images/Shell/backgrounds/learning_zone_2_page_bg_play.png");
  background-repeat: repeat;
  background-size: 120px;
  height: 100%; }

.read-background, .read-background-solo {
  background-color: #D299E6;
  background-image: url("/images/Shell/backgrounds/learning_zone_2_page_bg_read.png");
  background-repeat: repeat;
  background-size: 120px;
  min-height: calc(100vh - 90px); }
  @media screen and (max-width: 1024px) {
    .read-background, .read-background-solo {
      min-height: calc(100vh - 75px); } }

.read-background-browse {
  background-color: #D299E6;
  background-image: url("/images/Shell/backgrounds/learning_zone_2_page_bg_read.png");
  background-repeat: repeat;
  background-size: 120px;
  height: 100%; }

.explore-background {
  background-color: #7FD5A0;
  background-image: url("/images/Shell/backgrounds/learning_zone_2_page_bg_explore.png");
  background-repeat: repeat;
  background-size: 120px;
  min-height: calc(100vh - 90px); }
  @media screen and (max-width: 1024px) {
    .explore-background {
      min-height: calc(100vh - 75px); } }

.home-background {
  background-color: #BCE364;
  background-image: url("/images/Shell/backgrounds/learning_zone_2_page_bg_home.png");
  background-repeat: repeat;
  background-size: 120px;
  min-height: calc(100vh - 90px); }
  @media screen and (max-width: 1024px) {
    .home-background {
      min-height: calc(100vh - 75px); } }

.error-background {
  background-color: #E6E6E6;
  background-image: url("/images/Shell/backgrounds/learning_zone_2_page_bg_error.png");
  background-repeat: repeat;
  background-size: 120px;
  min-height: calc(100vh - 90px); }
  @media screen and (max-width: 1024px) {
    .error-background {
      min-height: calc(100vh - 75px); } }

.ToolbarButtonComponent .toolbarIcon {
  font-size: 39px;
  color: #4D4D4D; }

.ToolbarButtonComponent .tool {
  width: 44px;
  height: 44px;
  text-align: center; }
  @media screen and (hover: hover) {
    .ToolbarButtonComponent .tool.tool-hover:hover {
      cursor: pointer;
      -webkit-transform: scale(1.3);
              transform: scale(1.3);
      -webkit-transition: -webkit-transform 0.3s ease-in-out;
      transition: -webkit-transform 0.3s ease-in-out;
      transition: transform 0.3s ease-in-out;
      transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out; } }
  @media screen and (hover: hover) and (max-width: 1200px) {
    .ToolbarButtonComponent .tool.tool-hover:hover {
      -webkit-transform: scale(1.15);
              transform: scale(1.15);
      -webkit-transition: -webkit-transform 0.3s ease-in-out;
      transition: -webkit-transform 0.3s ease-in-out;
      transition: transform 0.3s ease-in-out;
      transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out; } }
  @media screen and (hover: hover) {
    .ToolbarButtonComponent .tool.no-tool-hover {
      cursor: default; } }
  .ToolbarButtonComponent .tool.tool-inactive {
    opacity: 0.5; }

.ToolbarButtonComponent button {
  all: unset; }

.ToolbarButtonComponent .activeToolbarIconColor {
  color: #006DA8; }

/*------------------------*/
/*------------------------*/
/*------------------------*/
/*------------------------*/
/* Pablo */
/* Hover Transition */
/* Hover Transition */
/* Pablo */
.create-background {
  background-color: #FDCF56;
  background-image: url("/images/Shell/backgrounds/learning_zone_2_page_bg_create.png");
  background-repeat: repeat;
  background-size: 120px;
  min-height: calc(100vh - 90px); }
  @media screen and (max-width: 1024px) {
    .create-background {
      min-height: calc(100vh - 75px); } }

.play-background, .play-background-solo {
  background-color: #4FD3D7;
  background-image: url("/images/Shell/backgrounds/learning_zone_2_page_bg_play.png");
  background-repeat: repeat;
  background-size: 120px;
  min-height: calc(100vh - 90px); }
  @media screen and (max-width: 1024px) {
    .play-background, .play-background-solo {
      min-height: calc(100vh - 75px); } }

.play-background-browse {
  background-color: #4FD3D7;
  background-image: url("/images/Shell/backgrounds/learning_zone_2_page_bg_play.png");
  background-repeat: repeat;
  background-size: 120px;
  height: 100%; }

.read-background, .read-background-solo {
  background-color: #D299E6;
  background-image: url("/images/Shell/backgrounds/learning_zone_2_page_bg_read.png");
  background-repeat: repeat;
  background-size: 120px;
  min-height: calc(100vh - 90px); }
  @media screen and (max-width: 1024px) {
    .read-background, .read-background-solo {
      min-height: calc(100vh - 75px); } }

.read-background-browse {
  background-color: #D299E6;
  background-image: url("/images/Shell/backgrounds/learning_zone_2_page_bg_read.png");
  background-repeat: repeat;
  background-size: 120px;
  height: 100%; }

.explore-background {
  background-color: #7FD5A0;
  background-image: url("/images/Shell/backgrounds/learning_zone_2_page_bg_explore.png");
  background-repeat: repeat;
  background-size: 120px;
  min-height: calc(100vh - 90px); }
  @media screen and (max-width: 1024px) {
    .explore-background {
      min-height: calc(100vh - 75px); } }

.home-background {
  background-color: #BCE364;
  background-image: url("/images/Shell/backgrounds/learning_zone_2_page_bg_home.png");
  background-repeat: repeat;
  background-size: 120px;
  min-height: calc(100vh - 90px); }
  @media screen and (max-width: 1024px) {
    .home-background {
      min-height: calc(100vh - 75px); } }

.error-background {
  background-color: #E6E6E6;
  background-image: url("/images/Shell/backgrounds/learning_zone_2_page_bg_error.png");
  background-repeat: repeat;
  background-size: 120px;
  min-height: calc(100vh - 90px); }
  @media screen and (max-width: 1024px) {
    .error-background {
      min-height: calc(100vh - 75px); } }

.Footer {
  width: 100%;
  height: 60px;
  background-color: #F0F9DC; }
  @media (max-width: 1024px) {
    .Footer {
      height: 80px; } }
  .Footer .footer-content {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 0 auto;
    max-width: 1280px; }
  .Footer .footer-branding {
    margin-left: 25px; }
  .Footer .link-container {
    margin-left: auto;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    margin-right: 25px;
    flex-grow: 1; }
  .Footer .links {
    line-height: 1.1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%; }
    @media (max-width: 1280px) {
      .Footer .links {
        flex-direction: column;
        justify-content: center;
        align-items: flex-end; } }
  .Footer .footer-link {
    line-height: 1;
    font-size: 13px;
    text-decoration: none;
    color: #333333;
    font-family: "Open Sans", sans-serif; }
  .Footer .copyright {
    font-size: 13px;
    font-family: "Open Sans", sans-serif;
    color: #666666; }
  .Footer .footer-link:hover, .Footer .footer-link:focus {
    cursor: pointer;
    color: #006DC1;
    text-decoration: underline; }
    .Footer .footer-link:hover::after, .Footer .footer-link:focus::after {
      cursor: pointer;
      color: #333333;
      text-decoration: none !important; }
  .Footer .footer-link::after {
    display: inline-block;
    padding: 0 7px 0 7px;
    content: "\2022"; }
  .Footer a:last-child::after {
    padding: 0;
    content: ""; }

/*------------------------*/
/*------------------------*/
/*------------------------*/
/*------------------------*/
/* Pablo */
/* Hover Transition */
/* Hover Transition */
/* Pablo */
.create-background {
  background-color: #FDCF56;
  background-image: url("/images/Shell/backgrounds/learning_zone_2_page_bg_create.png");
  background-repeat: repeat;
  background-size: 120px;
  min-height: calc(100vh - 90px); }
  @media screen and (max-width: 1024px) {
    .create-background {
      min-height: calc(100vh - 75px); } }

.play-background, .play-background-solo {
  background-color: #4FD3D7;
  background-image: url("/images/Shell/backgrounds/learning_zone_2_page_bg_play.png");
  background-repeat: repeat;
  background-size: 120px;
  min-height: calc(100vh - 90px); }
  @media screen and (max-width: 1024px) {
    .play-background, .play-background-solo {
      min-height: calc(100vh - 75px); } }

.play-background-browse {
  background-color: #4FD3D7;
  background-image: url("/images/Shell/backgrounds/learning_zone_2_page_bg_play.png");
  background-repeat: repeat;
  background-size: 120px;
  height: 100%; }

.read-background, .read-background-solo {
  background-color: #D299E6;
  background-image: url("/images/Shell/backgrounds/learning_zone_2_page_bg_read.png");
  background-repeat: repeat;
  background-size: 120px;
  min-height: calc(100vh - 90px); }
  @media screen and (max-width: 1024px) {
    .read-background, .read-background-solo {
      min-height: calc(100vh - 75px); } }

.read-background-browse {
  background-color: #D299E6;
  background-image: url("/images/Shell/backgrounds/learning_zone_2_page_bg_read.png");
  background-repeat: repeat;
  background-size: 120px;
  height: 100%; }

.explore-background {
  background-color: #7FD5A0;
  background-image: url("/images/Shell/backgrounds/learning_zone_2_page_bg_explore.png");
  background-repeat: repeat;
  background-size: 120px;
  min-height: calc(100vh - 90px); }
  @media screen and (max-width: 1024px) {
    .explore-background {
      min-height: calc(100vh - 75px); } }

.home-background {
  background-color: #BCE364;
  background-image: url("/images/Shell/backgrounds/learning_zone_2_page_bg_home.png");
  background-repeat: repeat;
  background-size: 120px;
  min-height: calc(100vh - 90px); }
  @media screen and (max-width: 1024px) {
    .home-background {
      min-height: calc(100vh - 75px); } }

.error-background {
  background-color: #E6E6E6;
  background-image: url("/images/Shell/backgrounds/learning_zone_2_page_bg_error.png");
  background-repeat: repeat;
  background-size: 120px;
  min-height: calc(100vh - 90px); }
  @media screen and (max-width: 1024px) {
    .error-background {
      min-height: calc(100vh - 75px); } }

.HomeTemplate .sixteen-nine .content .hp--flex-wrapper > button.hp--ratio--button {
  cursor: pointer;
  border: 0;
  border-radius: 2vw;
  background-color: #fff;
  width: 20%;
  max-width: 300px !important;
  max-height: 375px !important;
  box-shadow: 0 8px 0 rgba(0, 0, 0, 0.3);
  margin: 0 auto;
  padding: 1vw;
  box-sizing: border-box;
  margin-bottom: 20px;
  clear: both;
  height: unset;
  z-index: 3; }
  .HomeTemplate .sixteen-nine .content .hp--flex-wrapper > button.hp--ratio--button img {
    width: 100%;
    height: 100%;
    max-width: 215px;
    max-height: 189px; }
  .HomeTemplate .sixteen-nine .content .hp--flex-wrapper > button.hp--ratio--button .hp--button--label {
    width: 100%;
    background-color: #D63F15;
    border-radius: 40px;
    color: #fff;
    font-family: "Fredoka One", cursive;
    max-height: 3.5vw;
    line-height: 3.5vw;
    text-align: center;
    text-transform: uppercase;
    margin-top: 1.5vw; }
  .HomeTemplate .sixteen-nine .content .hp--flex-wrapper > button.hp--ratio--button::before {
    content: "";
    width: 1px;
    margin-left: -1px;
    float: left;
    height: 0;
    padding-top: 120%; }
  .HomeTemplate .sixteen-nine .content .hp--flex-wrapper > button.hp--ratio--button::after {
    /* to clear float */
    content: "";
    display: table;
    clear: both; }
  .HomeTemplate .sixteen-nine .content .hp--flex-wrapper > button.hp--ratio--button.nav-button:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    cursor: pointer;
    -webkit-transition: -webkit-transform 0.3s ease-in-out;
    transition: -webkit-transform 0.3s ease-in-out;
    transition: transform 0.3s ease-in-out;
    transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out; }
  @media screen and (min-width: 1278px) {
    .HomeTemplate .sixteen-nine .content .hp--flex-wrapper > button.hp--ratio--button .hp--button--label {
      max-height: 3.5vw;
      line-height: 3.5vw;
      margin-top: 1vw; } }

.pt-BR .HomeTemplate .sixteen-nine .content .hp--flex-wrapper > button.hp--ratio--button .hp--button--label {
  font-size: 1.5vw; }

@media screen and (min-width: 1281px) {
  .pt-BR .HomeTemplate .sixteen-nine .content .hp--flex-wrapper > button.hp--ratio--button .hp--button--label {
    font-size: 18px; } }

.en-US .HomeTemplate .sixteen-nine .content .hp--flex-wrapper > button.hp--ratio--button .hp--button--label {
  font-size: 2.5vw; }

@media screen and (min-width: 1281px) {
  .en-US .HomeTemplate .sixteen-nine .content .hp--flex-wrapper > button.hp--ratio--button .hp--button--label {
    font-size: 30px; } }

/*------------------------*/
/*------------------------*/
/*------------------------*/
/*------------------------*/
/* Pablo */
/* Hover Transition */
/* Hover Transition */
/* Pablo */
.create-background {
  background-color: #FDCF56;
  background-image: url("/images/Shell/backgrounds/learning_zone_2_page_bg_create.png");
  background-repeat: repeat;
  background-size: 120px;
  min-height: calc(100vh - 90px); }
  @media screen and (max-width: 1024px) {
    .create-background {
      min-height: calc(100vh - 75px); } }

.play-background, .play-background-solo {
  background-color: #4FD3D7;
  background-image: url("/images/Shell/backgrounds/learning_zone_2_page_bg_play.png");
  background-repeat: repeat;
  background-size: 120px;
  min-height: calc(100vh - 90px); }
  @media screen and (max-width: 1024px) {
    .play-background, .play-background-solo {
      min-height: calc(100vh - 75px); } }

.play-background-browse {
  background-color: #4FD3D7;
  background-image: url("/images/Shell/backgrounds/learning_zone_2_page_bg_play.png");
  background-repeat: repeat;
  background-size: 120px;
  height: 100%; }

.read-background, .read-background-solo {
  background-color: #D299E6;
  background-image: url("/images/Shell/backgrounds/learning_zone_2_page_bg_read.png");
  background-repeat: repeat;
  background-size: 120px;
  min-height: calc(100vh - 90px); }
  @media screen and (max-width: 1024px) {
    .read-background, .read-background-solo {
      min-height: calc(100vh - 75px); } }

.read-background-browse {
  background-color: #D299E6;
  background-image: url("/images/Shell/backgrounds/learning_zone_2_page_bg_read.png");
  background-repeat: repeat;
  background-size: 120px;
  height: 100%; }

.explore-background {
  background-color: #7FD5A0;
  background-image: url("/images/Shell/backgrounds/learning_zone_2_page_bg_explore.png");
  background-repeat: repeat;
  background-size: 120px;
  min-height: calc(100vh - 90px); }
  @media screen and (max-width: 1024px) {
    .explore-background {
      min-height: calc(100vh - 75px); } }

.home-background {
  background-color: #BCE364;
  background-image: url("/images/Shell/backgrounds/learning_zone_2_page_bg_home.png");
  background-repeat: repeat;
  background-size: 120px;
  min-height: calc(100vh - 90px); }
  @media screen and (max-width: 1024px) {
    .home-background {
      min-height: calc(100vh - 75px); } }

.error-background {
  background-color: #E6E6E6;
  background-image: url("/images/Shell/backgrounds/learning_zone_2_page_bg_error.png");
  background-repeat: repeat;
  background-size: 120px;
  min-height: calc(100vh - 90px); }
  @media screen and (max-width: 1024px) {
    .error-background {
      min-height: calc(100vh - 75px); } }

.HomeTemplate, .home-template {
  min-height: calc(100vh - 90px - 60px);
  padding-top: 80px; }
  @media (max-width: 1024px) {
    .HomeTemplate, .home-template {
      min-height: calc(100vh - 75px - 80px); } }
  .HomeTemplate .wrapper, .home-template .wrapper {
    margin: 25px; }
  .HomeTemplate .sixteen-nine, .home-template .sixteen-nine {
    position: relative; }
  @media screen and (max-width: 1278px) {
    .HomeTemplate .sixteen-nine:before, .home-template .sixteen-nine:before {
      display: block;
      content: '';
      width: 100%;
      padding-top: 56.25%; } }
  .HomeTemplate .sixteen-nine > .content, .home-template .sixteen-nine > .content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: transparent;
    margin: auto;
    max-width: 1279px;
    max-height: 719.4375px; }
    @media screen and (min-width: 1279px) {
      .HomeTemplate .sixteen-nine > .content, .home-template .sixteen-nine > .content {
        width: 1229px;
        height: 691.3125px;
        display: block;
        position: inherit; } }
  .HomeTemplate .footer-nav, .home-template .footer-nav {
    margin: 0 auto;
    height: 40vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center; }
    .HomeTemplate .footer-nav ul, .home-template .footer-nav ul {
      margin: 0 auto;
      width: 100%;
      text-align: center;
      list-style: none;
      padding: 0;
      display: flex;
      justify-content: space-evenly; }
      .HomeTemplate .footer-nav ul li, .home-template .footer-nav ul li {
        float: left;
        margin-left: 10px; }
        .HomeTemplate .footer-nav ul li a img, .home-template .footer-nav ul li a img {
          height: 220px; }
    @media screen and (max-width: 1024px) {
      .HomeTemplate .footer-nav, .home-template .footer-nav {
        height: 100px; } }
  .HomeTemplate.homeBackground, .home-template.homeBackground {
    background-color: #BCE364;
    background-image: url("/images/Shell/backgrounds/learning_zone_2_page_bg_home.png");
    background-repeat: repeat;
    background-size: 120px; }
  .HomeTemplate .hp--flex-wrapper, .home-template .hp--flex-wrapper {
    display: flex;
    margin-top: 50px; }

/*------------------------*/
/*------------------------*/
/*------------------------*/
/*------------------------*/
/* Pablo */
/* Hover Transition */
/* Hover Transition */
/* Pablo */
.create-background {
  background-color: #FDCF56;
  background-image: url("/images/Shell/backgrounds/learning_zone_2_page_bg_create.png");
  background-repeat: repeat;
  background-size: 120px;
  min-height: calc(100vh - 90px); }
  @media screen and (max-width: 1024px) {
    .create-background {
      min-height: calc(100vh - 75px); } }

.play-background, .play-background-solo {
  background-color: #4FD3D7;
  background-image: url("/images/Shell/backgrounds/learning_zone_2_page_bg_play.png");
  background-repeat: repeat;
  background-size: 120px;
  min-height: calc(100vh - 90px); }
  @media screen and (max-width: 1024px) {
    .play-background, .play-background-solo {
      min-height: calc(100vh - 75px); } }

.play-background-browse {
  background-color: #4FD3D7;
  background-image: url("/images/Shell/backgrounds/learning_zone_2_page_bg_play.png");
  background-repeat: repeat;
  background-size: 120px;
  height: 100%; }

.read-background, .read-background-solo {
  background-color: #D299E6;
  background-image: url("/images/Shell/backgrounds/learning_zone_2_page_bg_read.png");
  background-repeat: repeat;
  background-size: 120px;
  min-height: calc(100vh - 90px); }
  @media screen and (max-width: 1024px) {
    .read-background, .read-background-solo {
      min-height: calc(100vh - 75px); } }

.read-background-browse {
  background-color: #D299E6;
  background-image: url("/images/Shell/backgrounds/learning_zone_2_page_bg_read.png");
  background-repeat: repeat;
  background-size: 120px;
  height: 100%; }

.explore-background {
  background-color: #7FD5A0;
  background-image: url("/images/Shell/backgrounds/learning_zone_2_page_bg_explore.png");
  background-repeat: repeat;
  background-size: 120px;
  min-height: calc(100vh - 90px); }
  @media screen and (max-width: 1024px) {
    .explore-background {
      min-height: calc(100vh - 75px); } }

.home-background {
  background-color: #BCE364;
  background-image: url("/images/Shell/backgrounds/learning_zone_2_page_bg_home.png");
  background-repeat: repeat;
  background-size: 120px;
  min-height: calc(100vh - 90px); }
  @media screen and (max-width: 1024px) {
    .home-background {
      min-height: calc(100vh - 75px); } }

.error-background {
  background-color: #E6E6E6;
  background-image: url("/images/Shell/backgrounds/learning_zone_2_page_bg_error.png");
  background-repeat: repeat;
  background-size: 120px;
  min-height: calc(100vh - 90px); }
  @media screen and (max-width: 1024px) {
    .error-background {
      min-height: calc(100vh - 75px); } }

.HomePage {
  height: auto;
  width: 100%;
  background-color: #F0F9DC; }
  .HomePage .title-container {
    width: 80%;
    height: auto;
    max-width: 1100px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center; }
  .HomePage .hello-image {
    max-width: 400px;
    position: absolute;
    left: 0; }
  .HomePage .message-container {
    padding: 0 0 0 40px; }
  .HomePage .title-message {
    margin: 0;
    font-family: "Fredoka One", cursive;
    padding: 10px 0 10px 0;
    font-size: 34px;
    line-height: 43px;
    text-align: left;
    color: #01639C; }
    @media (max-width: 1278px) {
      .HomePage .title-message {
        font-size: 2.5vw;
        line-height: 3.5vw;
        padding: 9px 0 9px 0; } }

.en-US .HomePage .hello-image {
  top: -65px; }
  @media (max-width: 1278px) {
    .en-US .HomePage .hello-image {
      top: -50px; } }
  @media (max-width: 1022px) {
    .en-US .HomePage .hello-image {
      top: -42px; } }

.pt-BR .HomePage .hello-image {
  top: -43px; }
  @media (max-width: 1278px) {
    .pt-BR .HomePage .hello-image {
      top: -37px; } }
  @media (max-width: 1022px) {
    .pt-BR .HomePage .hello-image {
      top: -30px; } }

/*------------------------*/
/*------------------------*/
/*------------------------*/
/*------------------------*/
/* Pablo */
/* Hover Transition */
/* Hover Transition */
/* Pablo */
.create-background {
  background-color: #FDCF56;
  background-image: url("/images/Shell/backgrounds/learning_zone_2_page_bg_create.png");
  background-repeat: repeat;
  background-size: 120px;
  min-height: calc(100vh - 90px); }
  @media screen and (max-width: 1024px) {
    .create-background {
      min-height: calc(100vh - 75px); } }

.play-background, .play-background-solo {
  background-color: #4FD3D7;
  background-image: url("/images/Shell/backgrounds/learning_zone_2_page_bg_play.png");
  background-repeat: repeat;
  background-size: 120px;
  min-height: calc(100vh - 90px); }
  @media screen and (max-width: 1024px) {
    .play-background, .play-background-solo {
      min-height: calc(100vh - 75px); } }

.play-background-browse {
  background-color: #4FD3D7;
  background-image: url("/images/Shell/backgrounds/learning_zone_2_page_bg_play.png");
  background-repeat: repeat;
  background-size: 120px;
  height: 100%; }

.read-background, .read-background-solo {
  background-color: #D299E6;
  background-image: url("/images/Shell/backgrounds/learning_zone_2_page_bg_read.png");
  background-repeat: repeat;
  background-size: 120px;
  min-height: calc(100vh - 90px); }
  @media screen and (max-width: 1024px) {
    .read-background, .read-background-solo {
      min-height: calc(100vh - 75px); } }

.read-background-browse {
  background-color: #D299E6;
  background-image: url("/images/Shell/backgrounds/learning_zone_2_page_bg_read.png");
  background-repeat: repeat;
  background-size: 120px;
  height: 100%; }

.explore-background {
  background-color: #7FD5A0;
  background-image: url("/images/Shell/backgrounds/learning_zone_2_page_bg_explore.png");
  background-repeat: repeat;
  background-size: 120px;
  min-height: calc(100vh - 90px); }
  @media screen and (max-width: 1024px) {
    .explore-background {
      min-height: calc(100vh - 75px); } }

.home-background {
  background-color: #BCE364;
  background-image: url("/images/Shell/backgrounds/learning_zone_2_page_bg_home.png");
  background-repeat: repeat;
  background-size: 120px;
  min-height: calc(100vh - 90px); }
  @media screen and (max-width: 1024px) {
    .home-background {
      min-height: calc(100vh - 75px); } }

.error-background {
  background-color: #E6E6E6;
  background-image: url("/images/Shell/backgrounds/learning_zone_2_page_bg_error.png");
  background-repeat: repeat;
  background-size: 120px;
  min-height: calc(100vh - 90px); }
  @media screen and (max-width: 1024px) {
    .error-background {
      min-height: calc(100vh - 75px); } }

.Books-Page iframe {
  padding: 0;
  margin: 0;
  border: 0;
  width: 750px;
  height: 750px;
  margin: 0 auto; }

/*------------------------*/
/*------------------------*/
/*------------------------*/
/*------------------------*/
/* Pablo */
/* Hover Transition */
/* Hover Transition */
/* Pablo */
.create-background {
  background-color: #FDCF56;
  background-image: url("/images/Shell/backgrounds/learning_zone_2_page_bg_create.png");
  background-repeat: repeat;
  background-size: 120px;
  min-height: calc(100vh - 90px); }
  @media screen and (max-width: 1024px) {
    .create-background {
      min-height: calc(100vh - 75px); } }

.play-background, .play-background-solo {
  background-color: #4FD3D7;
  background-image: url("/images/Shell/backgrounds/learning_zone_2_page_bg_play.png");
  background-repeat: repeat;
  background-size: 120px;
  min-height: calc(100vh - 90px); }
  @media screen and (max-width: 1024px) {
    .play-background, .play-background-solo {
      min-height: calc(100vh - 75px); } }

.play-background-browse {
  background-color: #4FD3D7;
  background-image: url("/images/Shell/backgrounds/learning_zone_2_page_bg_play.png");
  background-repeat: repeat;
  background-size: 120px;
  height: 100%; }

.read-background, .read-background-solo {
  background-color: #D299E6;
  background-image: url("/images/Shell/backgrounds/learning_zone_2_page_bg_read.png");
  background-repeat: repeat;
  background-size: 120px;
  min-height: calc(100vh - 90px); }
  @media screen and (max-width: 1024px) {
    .read-background, .read-background-solo {
      min-height: calc(100vh - 75px); } }

.read-background-browse {
  background-color: #D299E6;
  background-image: url("/images/Shell/backgrounds/learning_zone_2_page_bg_read.png");
  background-repeat: repeat;
  background-size: 120px;
  height: 100%; }

.explore-background {
  background-color: #7FD5A0;
  background-image: url("/images/Shell/backgrounds/learning_zone_2_page_bg_explore.png");
  background-repeat: repeat;
  background-size: 120px;
  min-height: calc(100vh - 90px); }
  @media screen and (max-width: 1024px) {
    .explore-background {
      min-height: calc(100vh - 75px); } }

.home-background {
  background-color: #BCE364;
  background-image: url("/images/Shell/backgrounds/learning_zone_2_page_bg_home.png");
  background-repeat: repeat;
  background-size: 120px;
  min-height: calc(100vh - 90px); }
  @media screen and (max-width: 1024px) {
    .home-background {
      min-height: calc(100vh - 75px); } }

.error-background {
  background-color: #E6E6E6;
  background-image: url("/images/Shell/backgrounds/learning_zone_2_page_bg_error.png");
  background-repeat: repeat;
  background-size: 120px;
  min-height: calc(100vh - 90px); }
  @media screen and (max-width: 1024px) {
    .error-background {
      min-height: calc(100vh - 75px); } }

button.browse-nav-tab-component {
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  -webkit-transform: scale(1);
          transform: scale(1);
  border-radius: 8px;
  font-size: 18px;
  background-color: #fff;
  color: #000000;
  width: 220px;
  height: 46px;
  text-align: left;
  margin-bottom: 25px; }
  button.browse-nav-tab-component.play {
    box-shadow: 0px 3px 0px #0E5F8A;
    border: 3px solid #0E5F8A; }
  button.browse-nav-tab-component.tundra {
    box-shadow: 0px 3px 0px #2C536E;
    border: 3px solid #2C536E; }
  button.browse-nav-tab-component.taiga {
    box-shadow: 0px 3px 0px #4C3C69;
    border: 3px solid #4C3C69; }
  button.browse-nav-tab-component.tropical-rain-forest {
    box-shadow: 0px 3px 0px #1F5422;
    border: 3px solid #1F5422; }
  button.browse-nav-tab-component.temperate-forest {
    box-shadow: 0px 3px 0px #628040;
    border: 3px solid #628040; }
  button.browse-nav-tab-component.grassland {
    box-shadow: 0px 3px 0px #8c8331;
    border: 3px solid #8c8331; }
  button.browse-nav-tab-component.desert {
    box-shadow: 0px 3px 0px #a66d19;
    border: 3px solid #a66d19; }
  button.browse-nav-tab-component.read {
    box-shadow: 0px 3px 0px #4A4279;
    border: 3px solid #4A4279; }
  button.browse-nav-tab-component svg,
  button.browse-nav-tab-component .svg-inline--fa {
    width: 1.325em !important;
    height: 100%;
    vertical-align: -0.65em; }
  button.browse-nav-tab-component .svg-inline--fa {
    margin-left: 5px !important; }
    @media screen and (max-width: 1220px) {
      button.browse-nav-tab-component .svg-inline--fa {
        margin-left: 0 !important; } }
  button.browse-nav-tab-component .button-label {
    margin-left: 7px; }
    @media screen and (max-width: 1220px) {
      button.browse-nav-tab-component .button-label {
        margin-left: 0;
        display: none; } }
  button.browse-nav-tab-component.play.active, button.browse-nav-tab-component.play:hover, button.browse-nav-tab-component.play:active {
    color: white;
    background-color: #167F9C;
    box-shadow: 0px 3px 0px #0E5F8A;
    border: 3px solid;
    border-color: #0E5F8A;
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    -webkit-transition: all 0.5s;
    transition: all 0.5s; }
  button.browse-nav-tab-component.read.active, button.browse-nav-tab-component.read:hover, button.browse-nav-tab-component.read:active {
    color: #fff;
    background-color: #6E52A0;
    box-shadow: 0px 3px 0px #4A4279;
    border: 3px solid;
    border-color: #4A4279;
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    -webkit-transition: all 0.5s;
    transition: all 0.5s; }
  button.browse-nav-tab-component.tundra.active, button.browse-nav-tab-component.tundra:hover, button.browse-nav-tab-component.tundra:active {
    color: #fff;
    background-color: #407AA1;
    box-shadow: 0px 3px 0px #2C536E;
    border: 3px solid;
    border-color: #2C536E;
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    -webkit-transition: all 0.5s;
    transition: all 0.5s; }
  button.browse-nav-tab-component.taiga.active, button.browse-nav-tab-component.taiga:hover, button.browse-nav-tab-component.taiga:active {
    color: #fff;
    background-color: #8467B5;
    box-shadow: 0px 3px 0px #4C3C69;
    border: 3px solid;
    border-color: #4C3C69;
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    -webkit-transition: all 0.5s;
    transition: all 0.5s; }
  button.browse-nav-tab-component.tropical-rain-forest.active, button.browse-nav-tab-component.tropical-rain-forest:hover, button.browse-nav-tab-component.tropical-rain-forest:active {
    color: #fff;
    background-color: #328736;
    box-shadow: 0px 3px 0px #1F5422;
    border: 3px solid;
    border-color: #1F5422;
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    -webkit-transition: all 0.5s;
    transition: all 0.5s; }
  button.browse-nav-tab-component.temperate-forest.active, button.browse-nav-tab-component.temperate-forest:hover, button.browse-nav-tab-component.temperate-forest:active {
    color: #333333;
    background-color: #9ccc66;
    box-shadow: 0px 3px 0px #628040;
    border: 3px solid;
    border-color: #628040;
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    -webkit-transition: all 0.5s;
    transition: all 0.5s; }
  button.browse-nav-tab-component.grassland.active, button.browse-nav-tab-component.grassland:hover, button.browse-nav-tab-component.grassland:active {
    color: #333333;
    background-color: #ffee58;
    box-shadow: 0px 3px 0px #8c8331;
    border: 3px solid;
    border-color: #8c8331;
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    -webkit-transition: all 0.5s;
    transition: all 0.5s; }
  button.browse-nav-tab-component.desert.active, button.browse-nav-tab-component.desert:hover, button.browse-nav-tab-component.desert:active {
    color: #333333;
    background-color: #ffa827;
    box-shadow: 0px 3px 0px #a66d19;
    border: 3px solid;
    border-color: #a66d19;
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    -webkit-transition: all 0.5s;
    transition: all 0.5s; }
  @media screen and (max-width: 1220px) {
    button.browse-nav-tab-component {
      width: 44px;
      margin: 0 auto;
      margin-bottom: 25px;
      text-align: center;
      padding: 0 5px; } }

/*------------------------*/
/*------------------------*/
/*------------------------*/
/*------------------------*/
/* Pablo */
/* Hover Transition */
/* Hover Transition */
/* Pablo */
.create-background {
  background-color: #FDCF56;
  background-image: url("/images/Shell/backgrounds/learning_zone_2_page_bg_create.png");
  background-repeat: repeat;
  background-size: 120px;
  min-height: calc(100vh - 90px); }
  @media screen and (max-width: 1024px) {
    .create-background {
      min-height: calc(100vh - 75px); } }

.play-background, .play-background-solo {
  background-color: #4FD3D7;
  background-image: url("/images/Shell/backgrounds/learning_zone_2_page_bg_play.png");
  background-repeat: repeat;
  background-size: 120px;
  min-height: calc(100vh - 90px); }
  @media screen and (max-width: 1024px) {
    .play-background, .play-background-solo {
      min-height: calc(100vh - 75px); } }

.play-background-browse {
  background-color: #4FD3D7;
  background-image: url("/images/Shell/backgrounds/learning_zone_2_page_bg_play.png");
  background-repeat: repeat;
  background-size: 120px;
  height: 100%; }

.read-background, .read-background-solo {
  background-color: #D299E6;
  background-image: url("/images/Shell/backgrounds/learning_zone_2_page_bg_read.png");
  background-repeat: repeat;
  background-size: 120px;
  min-height: calc(100vh - 90px); }
  @media screen and (max-width: 1024px) {
    .read-background, .read-background-solo {
      min-height: calc(100vh - 75px); } }

.read-background-browse {
  background-color: #D299E6;
  background-image: url("/images/Shell/backgrounds/learning_zone_2_page_bg_read.png");
  background-repeat: repeat;
  background-size: 120px;
  height: 100%; }

.explore-background {
  background-color: #7FD5A0;
  background-image: url("/images/Shell/backgrounds/learning_zone_2_page_bg_explore.png");
  background-repeat: repeat;
  background-size: 120px;
  min-height: calc(100vh - 90px); }
  @media screen and (max-width: 1024px) {
    .explore-background {
      min-height: calc(100vh - 75px); } }

.home-background {
  background-color: #BCE364;
  background-image: url("/images/Shell/backgrounds/learning_zone_2_page_bg_home.png");
  background-repeat: repeat;
  background-size: 120px;
  min-height: calc(100vh - 90px); }
  @media screen and (max-width: 1024px) {
    .home-background {
      min-height: calc(100vh - 75px); } }

.error-background {
  background-color: #E6E6E6;
  background-image: url("/images/Shell/backgrounds/learning_zone_2_page_bg_error.png");
  background-repeat: repeat;
  background-size: 120px;
  min-height: calc(100vh - 90px); }
  @media screen and (max-width: 1024px) {
    .error-background {
      min-height: calc(100vh - 75px); } }

.browse-navigation {
  height: 80%;
  margin-top: 2%;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 400px;
  width: 90px;
  left: 0;
  z-index: 2; }

/*------------------------*/
/*------------------------*/
/*------------------------*/
/*------------------------*/
/* Pablo */
/* Hover Transition */
/* Hover Transition */
/* Pablo */
.create-background {
  background-color: #FDCF56;
  background-image: url("/images/Shell/backgrounds/learning_zone_2_page_bg_create.png");
  background-repeat: repeat;
  background-size: 120px;
  min-height: calc(100vh - 90px); }
  @media screen and (max-width: 1024px) {
    .create-background {
      min-height: calc(100vh - 75px); } }

.play-background, .play-background-solo {
  background-color: #4FD3D7;
  background-image: url("/images/Shell/backgrounds/learning_zone_2_page_bg_play.png");
  background-repeat: repeat;
  background-size: 120px;
  min-height: calc(100vh - 90px); }
  @media screen and (max-width: 1024px) {
    .play-background, .play-background-solo {
      min-height: calc(100vh - 75px); } }

.play-background-browse {
  background-color: #4FD3D7;
  background-image: url("/images/Shell/backgrounds/learning_zone_2_page_bg_play.png");
  background-repeat: repeat;
  background-size: 120px;
  height: 100%; }

.read-background, .read-background-solo {
  background-color: #D299E6;
  background-image: url("/images/Shell/backgrounds/learning_zone_2_page_bg_read.png");
  background-repeat: repeat;
  background-size: 120px;
  min-height: calc(100vh - 90px); }
  @media screen and (max-width: 1024px) {
    .read-background, .read-background-solo {
      min-height: calc(100vh - 75px); } }

.read-background-browse {
  background-color: #D299E6;
  background-image: url("/images/Shell/backgrounds/learning_zone_2_page_bg_read.png");
  background-repeat: repeat;
  background-size: 120px;
  height: 100%; }

.explore-background {
  background-color: #7FD5A0;
  background-image: url("/images/Shell/backgrounds/learning_zone_2_page_bg_explore.png");
  background-repeat: repeat;
  background-size: 120px;
  min-height: calc(100vh - 90px); }
  @media screen and (max-width: 1024px) {
    .explore-background {
      min-height: calc(100vh - 75px); } }

.home-background {
  background-color: #BCE364;
  background-image: url("/images/Shell/backgrounds/learning_zone_2_page_bg_home.png");
  background-repeat: repeat;
  background-size: 120px;
  min-height: calc(100vh - 90px); }
  @media screen and (max-width: 1024px) {
    .home-background {
      min-height: calc(100vh - 75px); } }

.error-background {
  background-color: #E6E6E6;
  background-image: url("/images/Shell/backgrounds/learning_zone_2_page_bg_error.png");
  background-repeat: repeat;
  background-size: 120px;
  min-height: calc(100vh - 90px); }
  @media screen and (max-width: 1024px) {
    .error-background {
      min-height: calc(100vh - 75px); } }

.browse-list-tile-component {
  margin-bottom: 20px;
  margin: 20px; }
  .browse-list-tile-component.read .browse-list-tile-component--card--bottom {
    background: #6E52A0; }
  .browse-list-tile-component.play .browse-list-tile-component--card--bottom {
    background: #167F9C; }
  .browse-list-tile-component .browse-list-tile-component--card {
    margin: 0 auto;
    width: 150px;
    display: flex;
    flex-direction: column;
    height: 100%; }
    .browse-list-tile-component .browse-list-tile-component--card .browse-list-tile-component--card--image {
      height: 182px;
      color: #000000; }
    .browse-list-tile-component .browse-list-tile-component--card .browse-list-tile-component--card--bottom {
      margin-top: 1px;
      text-align: center;
      color: #fff;
      width: 150px;
      padding: 10px;
      align-self: flex-end;
      box-sizing: border-box;
      vertical-align: middle;
      height: 100%;
      font-size: 15px;
      line-height: 16px;
      display: flex;
      flex-direction: column;
      align-items: center; }
      .browse-list-tile-component .browse-list-tile-component--card .browse-list-tile-component--card--bottom div {
        width: 100%;
        text-align: center;
        align-self: center; }
      .browse-list-tile-component .browse-list-tile-component--card .browse-list-tile-component--card--bottom small {
        font-size: 12px;
        line-height: 13px; }
  .browse-list-tile-component:hover {
    text-decoration: none; }

/*------------------------*/
/*------------------------*/
/*------------------------*/
/*------------------------*/
/* Pablo */
/* Hover Transition */
/* Hover Transition */
/* Pablo */
.create-background {
  background-color: #FDCF56;
  background-image: url("/images/Shell/backgrounds/learning_zone_2_page_bg_create.png");
  background-repeat: repeat;
  background-size: 120px;
  min-height: calc(100vh - 90px); }
  @media screen and (max-width: 1024px) {
    .create-background {
      min-height: calc(100vh - 75px); } }

.play-background, .play-background-solo {
  background-color: #4FD3D7;
  background-image: url("/images/Shell/backgrounds/learning_zone_2_page_bg_play.png");
  background-repeat: repeat;
  background-size: 120px;
  min-height: calc(100vh - 90px); }
  @media screen and (max-width: 1024px) {
    .play-background, .play-background-solo {
      min-height: calc(100vh - 75px); } }

.play-background-browse {
  background-color: #4FD3D7;
  background-image: url("/images/Shell/backgrounds/learning_zone_2_page_bg_play.png");
  background-repeat: repeat;
  background-size: 120px;
  height: 100%; }

.read-background, .read-background-solo {
  background-color: #D299E6;
  background-image: url("/images/Shell/backgrounds/learning_zone_2_page_bg_read.png");
  background-repeat: repeat;
  background-size: 120px;
  min-height: calc(100vh - 90px); }
  @media screen and (max-width: 1024px) {
    .read-background, .read-background-solo {
      min-height: calc(100vh - 75px); } }

.read-background-browse {
  background-color: #D299E6;
  background-image: url("/images/Shell/backgrounds/learning_zone_2_page_bg_read.png");
  background-repeat: repeat;
  background-size: 120px;
  height: 100%; }

.explore-background {
  background-color: #7FD5A0;
  background-image: url("/images/Shell/backgrounds/learning_zone_2_page_bg_explore.png");
  background-repeat: repeat;
  background-size: 120px;
  min-height: calc(100vh - 90px); }
  @media screen and (max-width: 1024px) {
    .explore-background {
      min-height: calc(100vh - 75px); } }

.home-background {
  background-color: #BCE364;
  background-image: url("/images/Shell/backgrounds/learning_zone_2_page_bg_home.png");
  background-repeat: repeat;
  background-size: 120px;
  min-height: calc(100vh - 90px); }
  @media screen and (max-width: 1024px) {
    .home-background {
      min-height: calc(100vh - 75px); } }

.error-background {
  background-color: #E6E6E6;
  background-image: url("/images/Shell/backgrounds/learning_zone_2_page_bg_error.png");
  background-repeat: repeat;
  background-size: 120px;
  min-height: calc(100vh - 90px); }
  @media screen and (max-width: 1024px) {
    .error-background {
      min-height: calc(100vh - 75px); } }

.browse-list-component--wrapper {
  border-width: 4px;
  border-style: solid;
  border-radius: 16px;
  background: rgba(255, 255, 255, 0.4);
  width: 90%;
  position: absolute;
  right: 0;
  padding: 20px;
  padding-left: 10%; }
  .browse-list-component--wrapper.play {
    border-color: #167F9C; }
  .browse-list-component--wrapper.read {
    border-color: #6E52A0; }
  @media screen and (max-width: 1220px) {
    .browse-list-component--wrapper {
      padding-left: 0; } }

.browse-list-component {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly; }

.play {
  color: #0E5F8A; }

.read {
  color: #4A4279; }

.browse-list-component--category-header-title {
  text-align: center;
  display: none;
  font-size: 2em; }
  @media screen and (max-width: 1220px) {
    .browse-list-component--category-header-title {
      display: block; } }

/*------------------------*/
/*------------------------*/
/*------------------------*/
/*------------------------*/
/* Pablo */
/* Hover Transition */
/* Hover Transition */
/* Pablo */
.create-background {
  background-color: #FDCF56;
  background-image: url("/images/Shell/backgrounds/learning_zone_2_page_bg_create.png");
  background-repeat: repeat;
  background-size: 120px;
  min-height: calc(100vh - 90px); }
  @media screen and (max-width: 1024px) {
    .create-background {
      min-height: calc(100vh - 75px); } }

.play-background, .play-background-solo {
  background-color: #4FD3D7;
  background-image: url("/images/Shell/backgrounds/learning_zone_2_page_bg_play.png");
  background-repeat: repeat;
  background-size: 120px;
  min-height: calc(100vh - 90px); }
  @media screen and (max-width: 1024px) {
    .play-background, .play-background-solo {
      min-height: calc(100vh - 75px); } }

.play-background-browse {
  background-color: #4FD3D7;
  background-image: url("/images/Shell/backgrounds/learning_zone_2_page_bg_play.png");
  background-repeat: repeat;
  background-size: 120px;
  height: 100%; }

.read-background, .read-background-solo {
  background-color: #D299E6;
  background-image: url("/images/Shell/backgrounds/learning_zone_2_page_bg_read.png");
  background-repeat: repeat;
  background-size: 120px;
  min-height: calc(100vh - 90px); }
  @media screen and (max-width: 1024px) {
    .read-background, .read-background-solo {
      min-height: calc(100vh - 75px); } }

.read-background-browse {
  background-color: #D299E6;
  background-image: url("/images/Shell/backgrounds/learning_zone_2_page_bg_read.png");
  background-repeat: repeat;
  background-size: 120px;
  height: 100%; }

.explore-background {
  background-color: #7FD5A0;
  background-image: url("/images/Shell/backgrounds/learning_zone_2_page_bg_explore.png");
  background-repeat: repeat;
  background-size: 120px;
  min-height: calc(100vh - 90px); }
  @media screen and (max-width: 1024px) {
    .explore-background {
      min-height: calc(100vh - 75px); } }

.home-background {
  background-color: #BCE364;
  background-image: url("/images/Shell/backgrounds/learning_zone_2_page_bg_home.png");
  background-repeat: repeat;
  background-size: 120px;
  min-height: calc(100vh - 90px); }
  @media screen and (max-width: 1024px) {
    .home-background {
      min-height: calc(100vh - 75px); } }

.error-background {
  background-color: #E6E6E6;
  background-image: url("/images/Shell/backgrounds/learning_zone_2_page_bg_error.png");
  background-repeat: repeat;
  background-size: 120px;
  min-height: calc(100vh - 90px); }
  @media screen and (max-width: 1024px) {
    .error-background {
      min-height: calc(100vh - 75px); } }

.browse-template .non-bound-container {
  position: relative; }
  .browse-template .non-bound-container > .content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: transparent;
    margin: auto;
    max-width: 1279px; }
    @media screen and (min-width: 1279px) {
      .browse-template .non-bound-container > .content {
        width: 1229px;
        height: auto;
        display: block;
        position: inherit; } }

/*------------------------*/
/*------------------------*/
/*------------------------*/
/*------------------------*/
/* Pablo */
/* Hover Transition */
/* Hover Transition */
/* Pablo */
.create-background {
  background-color: #FDCF56;
  background-image: url("/images/Shell/backgrounds/learning_zone_2_page_bg_create.png");
  background-repeat: repeat;
  background-size: 120px;
  min-height: calc(100vh - 90px); }
  @media screen and (max-width: 1024px) {
    .create-background {
      min-height: calc(100vh - 75px); } }

.play-background, .play-background-solo {
  background-color: #4FD3D7;
  background-image: url("/images/Shell/backgrounds/learning_zone_2_page_bg_play.png");
  background-repeat: repeat;
  background-size: 120px;
  min-height: calc(100vh - 90px); }
  @media screen and (max-width: 1024px) {
    .play-background, .play-background-solo {
      min-height: calc(100vh - 75px); } }

.play-background-browse {
  background-color: #4FD3D7;
  background-image: url("/images/Shell/backgrounds/learning_zone_2_page_bg_play.png");
  background-repeat: repeat;
  background-size: 120px;
  height: 100%; }

.read-background, .read-background-solo {
  background-color: #D299E6;
  background-image: url("/images/Shell/backgrounds/learning_zone_2_page_bg_read.png");
  background-repeat: repeat;
  background-size: 120px;
  min-height: calc(100vh - 90px); }
  @media screen and (max-width: 1024px) {
    .read-background, .read-background-solo {
      min-height: calc(100vh - 75px); } }

.read-background-browse {
  background-color: #D299E6;
  background-image: url("/images/Shell/backgrounds/learning_zone_2_page_bg_read.png");
  background-repeat: repeat;
  background-size: 120px;
  height: 100%; }

.explore-background {
  background-color: #7FD5A0;
  background-image: url("/images/Shell/backgrounds/learning_zone_2_page_bg_explore.png");
  background-repeat: repeat;
  background-size: 120px;
  min-height: calc(100vh - 90px); }
  @media screen and (max-width: 1024px) {
    .explore-background {
      min-height: calc(100vh - 75px); } }

.home-background {
  background-color: #BCE364;
  background-image: url("/images/Shell/backgrounds/learning_zone_2_page_bg_home.png");
  background-repeat: repeat;
  background-size: 120px;
  min-height: calc(100vh - 90px); }
  @media screen and (max-width: 1024px) {
    .home-background {
      min-height: calc(100vh - 75px); } }

.error-background {
  background-color: #E6E6E6;
  background-image: url("/images/Shell/backgrounds/learning_zone_2_page_bg_error.png");
  background-repeat: repeat;
  background-size: 120px;
  min-height: calc(100vh - 90px); }
  @media screen and (max-width: 1024px) {
    .error-background {
      min-height: calc(100vh - 75px); } }

.HeaderOnlytTemplate.homeBackground, .headerOnlyBackground.homeBackground {
  background-color: red;
  background-image: url("/images/Shell/backgrounds/learning_zone_2_page_bg_home.png");
  background-repeat: repeat;
  background-size: 120px; }

.HeaderOnlytTemplate .hp--flex-wrapper, .headerOnlyBackground .hp--flex-wrapper {
  display: flex;
  margin-top: 50px; }

.box {
  width: 410px;
  overflow: hidden;
  margin: 0 auto; }
  .box #mrotter #left-eye-open {
    opacity: 0; }
  .box #mrotter #left-eye-open-highlight {
    opacity: 0; }
  .box #mrotter #right-eye-open {
    opacity: 0; }
  .box #mrotter #right-eye-open-highlight {
    opacity: 0; }
  .box #mrotter #otter-head {
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%; }
  .box #mrotter #map {
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%; }
  .box #mrotter #hands {
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%; }

/*------------------------*/
/*------------------------*/
/*------------------------*/
/*------------------------*/
/* Pablo */
/* Hover Transition */
/* Hover Transition */
/* Pablo */
.create-background {
  background-color: #FDCF56;
  background-image: url("/images/Shell/backgrounds/learning_zone_2_page_bg_create.png");
  background-repeat: repeat;
  background-size: 120px;
  min-height: calc(100vh - 90px); }
  @media screen and (max-width: 1024px) {
    .create-background {
      min-height: calc(100vh - 75px); } }

.play-background, .play-background-solo {
  background-color: #4FD3D7;
  background-image: url("/images/Shell/backgrounds/learning_zone_2_page_bg_play.png");
  background-repeat: repeat;
  background-size: 120px;
  min-height: calc(100vh - 90px); }
  @media screen and (max-width: 1024px) {
    .play-background, .play-background-solo {
      min-height: calc(100vh - 75px); } }

.play-background-browse {
  background-color: #4FD3D7;
  background-image: url("/images/Shell/backgrounds/learning_zone_2_page_bg_play.png");
  background-repeat: repeat;
  background-size: 120px;
  height: 100%; }

.read-background, .read-background-solo {
  background-color: #D299E6;
  background-image: url("/images/Shell/backgrounds/learning_zone_2_page_bg_read.png");
  background-repeat: repeat;
  background-size: 120px;
  min-height: calc(100vh - 90px); }
  @media screen and (max-width: 1024px) {
    .read-background, .read-background-solo {
      min-height: calc(100vh - 75px); } }

.read-background-browse {
  background-color: #D299E6;
  background-image: url("/images/Shell/backgrounds/learning_zone_2_page_bg_read.png");
  background-repeat: repeat;
  background-size: 120px;
  height: 100%; }

.explore-background {
  background-color: #7FD5A0;
  background-image: url("/images/Shell/backgrounds/learning_zone_2_page_bg_explore.png");
  background-repeat: repeat;
  background-size: 120px;
  min-height: calc(100vh - 90px); }
  @media screen and (max-width: 1024px) {
    .explore-background {
      min-height: calc(100vh - 75px); } }

.home-background {
  background-color: #BCE364;
  background-image: url("/images/Shell/backgrounds/learning_zone_2_page_bg_home.png");
  background-repeat: repeat;
  background-size: 120px;
  min-height: calc(100vh - 90px); }
  @media screen and (max-width: 1024px) {
    .home-background {
      min-height: calc(100vh - 75px); } }

.error-background {
  background-color: #E6E6E6;
  background-image: url("/images/Shell/backgrounds/learning_zone_2_page_bg_error.png");
  background-repeat: repeat;
  background-size: 120px;
  min-height: calc(100vh - 90px); }
  @media screen and (max-width: 1024px) {
    .error-background {
      min-height: calc(100vh - 75px); } }

.generic-not-found {
  width: 60%;
  text-align: center;
  margin: 0 auto; }
  .generic-not-found h1 {
    font-size: 96px;
    color: #D63F15;
    margin-bottom: -30px; }
  .generic-not-found h2 {
    font-size: 52px;
    color: #D63F15; }
  .generic-not-found p {
    margin-top: 25px;
    font-size: 22px;
    color: #333333; }
  .generic-not-found img {
    margin-top: 0px; }
  .generic-not-found .link-button {
    display: inline-block;
    width: auto;
    margin: 0 auto;
    background-color: #D63F15;
    border-radius: 40px;
    color: #fff;
    font-family: 'Fredoka One', cursive;
    height: 35px;
    text-align: center;
    text-transform: uppercase;
    border: 3px solid brown;
    width: auto;
    padding-left: 10px;
    padding-right: 10px;
    margin-left: 5px;
    margin-right: 5px; }
    .generic-not-found .link-button:hover {
      text-decoration: none;
      -webkit-animation: swing 1s;
              animation: swing 1s; }

/*------------------------*/
/*------------------------*/
/*------------------------*/
/*------------------------*/
/* Pablo */
/* Hover Transition */
/* Hover Transition */
/* Pablo */
.create-background {
  background-color: #FDCF56;
  background-image: url("/images/Shell/backgrounds/learning_zone_2_page_bg_create.png");
  background-repeat: repeat;
  background-size: 120px;
  min-height: calc(100vh - 90px); }
  @media screen and (max-width: 1024px) {
    .create-background {
      min-height: calc(100vh - 75px); } }

.play-background, .play-background-solo {
  background-color: #4FD3D7;
  background-image: url("/images/Shell/backgrounds/learning_zone_2_page_bg_play.png");
  background-repeat: repeat;
  background-size: 120px;
  min-height: calc(100vh - 90px); }
  @media screen and (max-width: 1024px) {
    .play-background, .play-background-solo {
      min-height: calc(100vh - 75px); } }

.play-background-browse {
  background-color: #4FD3D7;
  background-image: url("/images/Shell/backgrounds/learning_zone_2_page_bg_play.png");
  background-repeat: repeat;
  background-size: 120px;
  height: 100%; }

.read-background, .read-background-solo {
  background-color: #D299E6;
  background-image: url("/images/Shell/backgrounds/learning_zone_2_page_bg_read.png");
  background-repeat: repeat;
  background-size: 120px;
  min-height: calc(100vh - 90px); }
  @media screen and (max-width: 1024px) {
    .read-background, .read-background-solo {
      min-height: calc(100vh - 75px); } }

.read-background-browse {
  background-color: #D299E6;
  background-image: url("/images/Shell/backgrounds/learning_zone_2_page_bg_read.png");
  background-repeat: repeat;
  background-size: 120px;
  height: 100%; }

.explore-background {
  background-color: #7FD5A0;
  background-image: url("/images/Shell/backgrounds/learning_zone_2_page_bg_explore.png");
  background-repeat: repeat;
  background-size: 120px;
  min-height: calc(100vh - 90px); }
  @media screen and (max-width: 1024px) {
    .explore-background {
      min-height: calc(100vh - 75px); } }

.home-background {
  background-color: #BCE364;
  background-image: url("/images/Shell/backgrounds/learning_zone_2_page_bg_home.png");
  background-repeat: repeat;
  background-size: 120px;
  min-height: calc(100vh - 90px); }
  @media screen and (max-width: 1024px) {
    .home-background {
      min-height: calc(100vh - 75px); } }

.error-background {
  background-color: #E6E6E6;
  background-image: url("/images/Shell/backgrounds/learning_zone_2_page_bg_error.png");
  background-repeat: repeat;
  background-size: 120px;
  min-height: calc(100vh - 90px); }
  @media screen and (max-width: 1024px) {
    .error-background {
      min-height: calc(100vh - 75px); } }

#game {
  background: #000000;
  touch-action-delay: none;
  touch-action: none;
  -ms-touch-action: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  display: block;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%); }

