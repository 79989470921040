@import '../../variables.scss';

.Footer {
  width: 100%;
  height: 60px;
  background-color: $shell-color;
  @media (max-width: $screen-sm-max + 1) {
    height: 80px;
  }
  
  .footer-content {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 0 auto;
    max-width: $screen-lg-min;
  }
  
  .footer-branding {
    margin-left: 25px;
  }
  
  .link-container {
    margin-left: auto;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    margin-right: 25px;
    flex-grow: 1;
  }
  
  .links {
    line-height: 1.1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    @media (max-width: $screen-lg-min) {
      flex-direction: column;
      justify-content: center;
      align-items: flex-end;
    }
  }
  
  .footer-link {
    line-height: 1;
    font-size: 13px;
    text-decoration: none;
    color: $dark-text;
    font-family: $font-family-sans-serif;
  }
  
  .copyright {
    font-size: 13px;
    font-family: $font-family-sans-serif;
    color: $tertiary-gray;
  }
  
  .footer-link {
    &:hover, &:focus {
      cursor: pointer;
      color: #006DC1;
      text-decoration: underline;
      
      &::after {
        cursor: pointer;
        color: $dark-text;
        text-decoration: none !important;
      }
    }
  }
  
  .footer-link::after {
    display: inline-block;
    padding: 0 7px 0 7px;
    content: "\2022";
  }
  
  a:last-child::after {
    padding: 0;
    content: "";
  }
}
