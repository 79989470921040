@import '../../../variables.scss';


.default-template {

}
.wrapper {
  margin: 0 25px;
}

.sixteen-nine {
  position: relative;
}
.sixteen-nine:before {
  @media screen and (max-width: $screen-md-max - 1) {
    display: block;
    content: '';
    width: 100%;
    padding-top: 56.25%;
  }
}

.sixteen-nine > .content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  // background-color: #ff5729;
  background: transparent;
  margin: auto;
  max-width: $screen-md-max;
  max-height: $screen-md-max * 0.5625;

  @media screen and (min-width: $screen-md-max) {
    width: $screen-md-max - 50 ;
    height: (($screen-md-max - 50) * 0.5625);
    display: block;
    position: inherit;
  }
}
.play-background-solo, .read-background-solo {
  .sixteen-nine > .content {
    border: solid 4px;
    border-radius: 16px;
    overflow: hidden;
  }
}
.play-background-solo {
  .sixteen-nine > .content {
    border-color: $tenor-blue;
  }
}
.read-background-solo {
  .sixteen-nine > .content {
    border-color:$tenor-purple;
    background: url('/images/Read/learning_zone_2_read_page_sides.png');
    background-repeat: repeat;
    background-size: 25px 25px;
  }
}
footer {
  height: 140px;
  // background-color: #a2ff29;
  display: flex;
  justify-content: center;
  align-items: center;
  ul {
    margin: 0 auto;
    width: 100%;
    text-align: center;
    list-style: none;
    padding: 0;
    li {
      float: left;
      margin-left: 10px;
    }
  }
  @media screen and (max-width: $screen-sm-max + 1) {
    height: 100px;
  }
}

button {
  width: 44px;
  // height: 44px;
  i {
    font-size: 24px;
  }
}

