@import './variables';

* {
  box-sizing: border-box;
}
*:focus {   
  outline: $focus-color 6px dashed !important; 
}

html, body, #root {
  height: 100vh;
  min-height: 100vh;
  max-height: 100vh;
  margin: 0; 
  padding: 0;
}

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@import './SCSS/mixins';
