.create-background {
  @include background($create-background-image, $create-background-color, false);
}

.play-background, .play-background-solo {
  @include background($play-background-image, $play-background-color, false);
}

.play-background-browse {
  @include background($play-background-image, $coloratura-blue, true);
}

.read-background, .read-background-solo {
  @include background($read-background-image, $coloratura-purple, false);
}

.read-background-browse {
  @include background($read-background-image, $coloratura-purple, true);
}

.explore-background {
  @include background($explore-background-image, $coloratura-green, false);
}

.home-background {
  @include background($home-background-image, $home-background-color, false);
}

.error-background {
  @include background($error-background-image, $error-background-color, false);
}

