@import '../../../variables';

$content-area-border-color: #db8500;
$arrow-right-border-top: 19px solid transparent;
$arrow-right-border-bottom: 19px solid transparent;
$arrow-right-border-left: 30px solid $content-area-border-color;
$arrow-left-border-top: 19px solid transparent;
$arrow-left-border-bottom: 19px solid transparent;
$arrow-left-border-right: 30px solid $content-area-border-color;
$controls-margin-left: 32px;
$controls-margin-right: 32px;
$content-container-border-radius: 16px;

.PabloPage {
  min-height: 100%;
  max-height: 100%;
  height: 100%;
  border: 4px solid $content-area-border-color;
  border-radius: $content-container-border-radius;
  
  .pablo-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative; 
    height: 100%;
  }
  
  #stickerbook_container {
    height: 100%;
    background-color: $stickerbook-color; 
    position: absolute;
  }
  
  #colorsCont, .controlsCont, #stickersCont, #backgroundsCont {
    top: 0;
    left: 0;
    position: relative;
    background-color: $toolbar-color;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center; 
    padding: 0 10px 0 10px;
    overflow: hidden;
  }
  
  .controlsCont {
    height: 100%;
    z-index: 1000;
  }
  
  .drawerCont {
    animation-duration: .5s;
  }

  #rightDrawerCont {
    padding-left: 6px;
  }

  #leftDrawerCont {
    padding-right: 6px;
  }
  
  #colorsCont, #stickersCont, #backgroundsCont {
    border: 2px solid $content-area-border-color;
    border-radius: 8px;
    height: calc(100% - 24px);
    margin-top: 12px;
    padding: 0 12px 0 12px;
    @media (max-width: 1200px) {
      height: 100%;
      margin-top: 0;
    }
  }
  
  #backgroundsCont {
    padding: 0 20px 0 20px;
  }
  
  #stickersCont {
    padding: 10px 12px 10px 12px;
  }

  #arrow-right {
    position: absolute;
    width: 0; 
    height: 0; 
    // Creating triangle for drawer
    border-top: $arrow-right-border-top; // Top point position
    border-bottom: $arrow-right-border-bottom; // Bottom point position
    border-left: $arrow-right-border-left; // Horizontal length
  }

  #arrow-right::after {
    content: '';
    width: 0;
    height: 0;
    border-top: 19px solid transparent;
    border-bottom: 19px solid transparent;
    border-left: 30px solid $toolbar-color;
    position: absolute;
    margin-top: -19px;
    margin-left: -34px;
  }
  
  #arrow-left {
    position: absolute;
    width: 0; 
    height: 0; 
    // Creating triangle for drawer
    border-top: $arrow-left-border-top; // Top point position
    border-bottom: $arrow-left-border-bottom; // Bottom point position
    border-right: $arrow-left-border-right; // Horizontal length
  }

  #arrow-left::after {
    content: '';
    width: 0;
    height: 0;
    border-top: 19px solid transparent;
    border-bottom: 19px solid transparent;
    border-right: 30px solid $toolbar-color;
    position: absolute;
    margin-top: -19px;
    margin-left: 4px;
  }
  
  .rightToolbarContainer, .leftToolbarContainer {
    position: absolute;
    z-index: 1000;
    display: flex;
    height: 100%;
    overflow: hidden;
  }
  
  #leftControlsCont {
    padding-top: 80px;
    border-right: 2px solid $content-area-border-color;
  }

  #rightControlsCont {
    border-left: 2px solid $content-area-border-color;
  }
  
  .rightToolbarContainer {
    right: 0;
    border-radius: 0 $content-border-radius $content-border-radius 0;
  }
  
  .leftToolbarContainer {
    left: 0;
    border-radius: $content-border-radius 0 0 $content-border-radius;
  }
  
  button {
    all: unset;
    
    // Added to meet tab index accessibility
    &:focus {
      outline: 5px auto -webkit-focus-ring-color;
    }
  }
  
  .hideDrawer {
    display: none;
  }
  
  #stickersCont .col {
    @media (max-width: 900px) {
      padding: 0 10px 0 10px;
    }
  }
  
  #backgroundsCont .col {
    padding: 0 20px 0 20px;
  }
  
  .hide {
    display: none !important;
  }
  
  .controlsMarginLeft {
    margin-left: $controls-margin-left;
  }
  
  .controlsMarginRight {
    margin-right: $controls-margin-right;
  }
  
  .active {
    transform: scale(1.3);
    @media(max-width: 1200px) {
      transform: scale(1.15);
    }
  }  
}

// Print media query to hide all page elements other than canvas
body {
  &.print {
    @media print {
      background-color: transparent;
      background-image: none;
      
      footer, header, .primary-navigation-wrapper, .toolbarContainer, .SelectionWindowComponent {
        display: none;
        visibility: hidden;
      }
      
      .PabloPage {
        border: none;
      }       
    }
  }
}
