@import '../../variables.scss';

.HomeTemplate .sixteen-nine .content .hp--flex-wrapper > button.hp--ratio--button {
  cursor: pointer;
  border: 0;
  border-radius: 2vw;
  background-color: $white;
  width: 20%;
  max-width: 300px !important;
  max-height: 375px !important;
  box-shadow: 0 8px 0 rgba($black, 0.30);
  // outline: 1px solid orange;
  margin: 0 auto;
  padding: 1vw;
  box-sizing: border-box;
  margin-bottom: 20px;
  clear: both;
  height: unset;
  z-index: 3;
  img {
    // margin-top: -2vw;
    width: 100%;
    height: 100%;
    max-width: 215px;
    max-height: 189px;
  }
  .hp--button--label {
    width: 100%;
    background-color: $tertiary-color;
    border-radius: 40px;
    color: $white;
    font-family: "Fredoka One", cursive;
    max-height: 3.5vw;
    line-height: 3.5vw;
    text-align: center;
    text-transform: uppercase;
    // margin-top: -1.45vw;
    margin-top: 1.5vw;
  }
  &::before {
    content: "";
    width: 1px;
    margin-left: -1px;
    float: left;
    height: 0;
    padding-top: 120%;
  }
  &::after {
    /* to clear float */
    content: "";
    display: table;
    clear: both;
  }
  &.nav-button:hover {
    transform: scale(1.1);
    cursor: pointer;
    transition: transform 0.3s ease-in-out;
  }
  // Above max size
  @media screen and (min-width: $screen-md-max - 1) {
    .hp--button--label {
      max-height: 3.5vw;
      line-height: 3.5vw;
      margin-top: 1vw;
    }
   }
}

.pt-BR {
  .HomeTemplate .sixteen-nine .content .hp--flex-wrapper>button.hp--ratio--button {
    .hp--button--label {
      font-size: 1.5vw;
    }

    // Above max size
    @media screen and (min-width: $screen-lg-min + 1) {
      .hp--button--label {
        font-size: 18px;
      }
    }
  }
}

.en-US {
  .HomeTemplate .sixteen-nine .content .hp--flex-wrapper>button.hp--ratio--button {
    .hp--button--label {
      font-size: 2.5vw;
    }

    // Above max size
    @media screen and (min-width: $screen-lg-min + 1) {
      .hp--button--label {
        font-size: 30px;
      }
    }
  }
}