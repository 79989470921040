@import '../../variables.scss';

button.browse-nav-tab-component {
  transition: all 0.5s;
  transform: scale(1);
  border-radius: 8px;
  font-size: 18px;
  background-color: $white;
  color: $button-dark-text;
  width: 220px;
  height: 46px;
  text-align: left;
  margin-bottom: 25px;
  &.play {
    box-shadow: 0px 3px 0px $bass-blue;
    border: 3px solid $bass-blue;
  }
  &.tundra {
    box-shadow: 0px 3px 0px $tundra-secondary-color;
    border: 3px solid $tundra-secondary-color;
  }
  &.taiga {
    box-shadow: 0px 3px 0px $taiga-secondary-color;
    border: 3px solid $taiga-secondary-color;
  }
  &.tropical-rain-forest {
    box-shadow: 0px 3px 0px $trop-forest-secondary-color;
    border: 3px solid $trop-forest-secondary-color;
  }
  &.temperate-forest {
    box-shadow: 0px 3px 0px $temp-forest-secondary-color;
    border: 3px solid $temp-forest-secondary-color;
  }
  &.grassland {
    box-shadow: 0px 3px 0px $grassland-secondary-color;
    border: 3px solid $grassland-secondary-color;
  }
  &.desert {
    box-shadow: 0px 3px 0px $desert-secondary-color;
    border: 3px solid $desert-secondary-color;
  }

  &.read {
    box-shadow: 0px 3px 0px $bass-purple;
    border: 3px solid $bass-purple;
  }

  svg,
  .svg-inline--fa {
    width: 1.325em !important;
    height: 100%;
    vertical-align: -0.65em;
  }
  .svg-inline--fa {
    margin-left: 5px!important;
    @media screen and (max-width: 1220px) {
      margin-left: 0!important;
    }
  }
  .button-label {
    margin-left: 7px;
    @media screen and (max-width: 1220px) {
      margin-left: 0;
      display: none;
    }
  }
  &.play.active, &.play:hover, &.play:active {
    color: white;
    @include collapsableButtons($tenor-blue, $bass-blue);
  }
  &.read.active, &.read:hover, &.read:active {
    color: $white;
    @include collapsableButtons($tenor-purple, $bass-purple);
  }
  //
  &.tundra.active, &.tundra:hover, &.tundra:active {
    color: $white;
    @include collapsableButtons($tundra-color, $tundra-secondary-color);
  }
  //
  &.taiga.active, &.taiga:hover, &.taiga:active {
    color: $white;
    @include collapsableButtons($taiga-color, $taiga-secondary-color);
  }
  //
  &.tropical-rain-forest.active, &.tropical-rain-forest:hover, &.tropical-rain-forest:active {
    color: $white;
    @include collapsableButtons($trop-forest-color, $trop-forest-secondary-color);
  }
  &.temperate-forest.active, &.temperate-forest:hover, &.temperate-forest:active {
    color: $dark-text;
    @include collapsableButtons($temp-forest-color, $temp-forest-secondary-color);
  }
  &.grassland.active, &.grassland:hover, &.grassland:active {
    color: $dark-text;
    @include collapsableButtons($grassland-color, $grassland-secondary-color);
  }
  &.desert.active, &.desert:hover, &.desert:active {
    color: $dark-text;
    @include collapsableButtons($desert-color, $desert-secondary-color);
  }
  

  @media screen and (max-width: 1220px) {
    width: 44px;
    margin: 0 auto;
    margin-bottom: 25px;

    text-align: center;
    padding: 0 5px;
  }
}
