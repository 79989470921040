@import '../../variables.scss';

@mixin collapsableButtons($property, $property2) {
  background-color: $property;
  box-shadow: 0px 3px 0px $property2;
  border: 3px solid;
  border-color: $property2;
  margin-top: 15px;
}

.button-transcript {
  @include collapsableButtons(#BA5D00, $secondary-color-shadow);
  border-radius: 8px;
  font-size: 18px;

  color: $white;
  width: 220px;
  height: 46px;
  text-align: center;
}
.media-overlay-figure {
  margin-top: 20px;
  text-align: center;
  .expand {
    background: #ccc;
    border: 0;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    color: #4D4D4D;
    width: 74px;
    height: 56px;
    margin-left: -74px;
    bottom: 0;
    position: absolute;
    align-self: flex-end;
    cursor: zoom-in;
    &:hover {
      color: #333;
      cursor: zoom-in;
    }
  }
  img {
    max-width: 100%;
    margin: 0 auto;
    max-height: 60vh;
  }
  .fullscreen {
    position: relative;
  }
  .fullscreen-enabled {
    display: flex;
    align-items: center;
    justify-content: center;
    .expand {
      // align-self: flex-end;
      background: rgba($white, 0.8);
      border: 0;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
      color: #808080;
      cursor: zoom-out;
      height: 56px;
      width: 74px;
      margin-left: -74px;
      position: inherit;

      &:hover {
        color: $secondary-color;
        cursor: zoom-out;
      }
    }
    img {
      max-height: 100vh;
    }
  }
}

h1.media-overlay--title {
  color: #BA5D00 !important;
  text-align: center !important;
}

.media-overlay-container {
  width: 1200px;
  height: auto;
  text-align: center;
  h1 {
    color: $secondary-color;
    text-align: center;
  }
  img {
    margin: 0 auto;
  }
  @media (max-width: $screen-sm-min) {
    max-width: 320px;
  }
  @media (min-width: $screen-sm-min) {
    max-width: 640px;
  }
  @media (min-width: $screen-md-min) {
    max-width: 760px;
  }
  @media (min-width: $screen-lg-min) {
    max-width: 1100px;
  }
  @media (min-width: $screen-xl-min) {
    max-width: 1400px;
  }
  figcaption {
    font-family: $font-family-sans-serif;
  }
  .caption {
    font-family: $font-family-sans-serif;
    font-size: $font-size-lg;
    color: #666;
    line-height: $line-height-std;
    margin-top: 15px;
  }
  .credit {
    font-family: $font-family-sans-serif;
    font-size: $font-size-base;
    color: #666;
    line-height: $line-height-std;
    font-style: italic;
  }
  .transcript {
    font-family: $font-family-sans-serif;
    font-size: $font-size-lg;
    color: #666;
    line-height: $line-height-std;
    margin-top: 15px;
    // max-height: 80px;
    // overflow: scroll;
    padding: 10px 10px;
    // border: 1px solid $secondary-gray;
  }
}

.media-overlay-loading {
  svg {
    margin: 0 auto;
  }
  text-align: center;
}

.media-overlay-head {
  button {
    all: unset;
    float: right;
    color: #4D4D4D;
    &:hover {
      color: #BA5D00;
    }
  }
}

.ReactModalPortal {
  .ReactModal__Overlay--after-open {
    background-color: rgba($white, 0.75) !important;
  }
}
